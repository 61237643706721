/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-shadow */
import { COIN } from './Order';

export class User {
  _id: string;
  email: string;
  password?: string;
  type: USER_TYPE;
  cuit: string;
  phoneNumber: string;
  name: string;
  searchName: string;
  isCommerce: boolean;
  label: USER_LABEL;
  flags: {
    termsAndConditions: boolean;
    validatedDocuments: boolean;
    mailConfirmed?: boolean;
    firstOnboarding?: boolean;
    appliedRefBenefit?: boolean;
    clubMember?: boolean;
  };
  fundsDocuments: {
    certificacionContable: DOCUMENT_STATUS;
    funds: DOCUMENT_STATUS;
    impuestoCedular: DOCUMENT_STATUS;
    selfie?: DOCUMENT_STATUS;
  };
  dnis: {
    front: DOCUMENT_STATUS;
    back: DOCUMENT_STATUS;
  };
  bankAccounts: {
    ARS: IBankAccount[];
    USD: IBankAccount[];
  };
  cryptoAccounts: ICryptoAccount[];
  balance?: IBalance;
  data?: IPerson;
  cryptoAddress: string;
  terraAddress: string;
  numberId: string;

  // AUX
  accessToken?: string;
  refreshToken?: string;
  activities?: any[];
  orders?: any[];
  deposits?: any[];
  withdrawRequests?: any[];
  transactions?: any[];
  benefitHistory?: any[];
  referralData?: any;
  companyData?: any;
  mediaData?: any[];
  amounts?: number[];
  security?: {
    twoFA: {
      status?: TWOFA_STATUS;
    };
  };
  medals?: IMedal[];
  status?: number;
  portfolio?: any[];
  passportCheck?: any;
  investorProfile?: any;
  isPoliticalPerson?: any;
  under10kUSDDeclarationSigned?: any;
  investorExperience?: any;
  isBanned?: boolean;
  work?: {
    id?: string;
    desc?: string;
  };
  whitelabelAddress?: string;
  whitelabelId?: string;
  exchangeCountry?: string;
  isArbitrator: boolean;
}

export interface IComplianceChecks {
  expiredAddress: boolean;
  expiredFunds: boolean;
  expiredPep: boolean;
  expiredPhone: boolean;
  isDead: boolean;
  isUifChange: boolean;
}

export interface ICryptoAccount {
  description: string;
  address: string;
  chain: number;
}

export interface IBankAccount {
  description: string;
  cbu: string;
  alias?: string;
  id?: number;
  name?: string;
  bankName?: string;
  bankCode?: string;
}

export interface IEmployee {
  userId: IEmployeeUserId;
  salary: number;
  isArs: boolean;
  coin: string;
}

export enum USER_LABEL {
  NORMAL,
  YELLOW,
  RED,
  BLACK,
  SELFIE_ACCEPTED,
  UNKNOWN_IDENTITY,
  REQUIRES_DOCUMENTATION,
}

export interface IEmployeeUserId {
  cuit: string;
  name: string;
}

export interface IBalance {
  fiat: {
    ARS: number;
    USD: number;
  };
  crypto: {
    [coin: string]: any;
  };
  bundle: IBundle;
}
export interface IBundle {
  [fiat: string]: {
    [ticker: string]: any;
  };
}
export enum USER_TYPE {
  PERSON = 0,
  COMPANY = 1,
  WHITELABEL = 2,
}

export interface IPerson {
  firstName?: string;
  lastName?: string;
  street?: string;
  numeration?: string;
  department?: string;
  birthDate?: string;
  sex?: string;
  civilState?: string;
  nationality?: string;
  postalCode?: string;
  province?: string;
  city?: string;
  occupation?: string;
  IVA?: string;
  ganancias?: string;
  isFacta?: boolean;
  isUIF?: boolean;
  isPEP?: boolean;
  pepDoc?: DOCUMENT_STATUS;
  uifDoc?: DOCUMENT_STATUS;
  rep?: string;
  documentation?: {
    ACTA_DESIGNACION?: DOCUMENT_STATUS;
    BALANCE?: DOCUMENT_STATUS;
    CONSTANCIA_DE_CUIT?: DOCUMENT_STATUS;
    ESTATUTO?: DOCUMENT_STATUS;
    IIBB?: DOCUMENT_STATUS;
    ULTIMO_BALANCE_CERTIFICADO?: DOCUMENT_STATUS;
  };
}

export interface IUserInfoField {
  firstName?: string;
  searchName?: string;
  lastName?: string;
  nationality?: string;
  province?: string;
  isUIF?: boolean;
  isPEP?: boolean;
  occupation?: string;
  city?: string;
  civilState?: string;
  street?: string;
  numeration?: string;
  department?: string;
  postalCode?: string;
  sex?: string;
  birthDate?: string;
  IVA?: string;
  ganancias?: string;
  isFacta?: boolean;
  name?: string;
  cuit?: string;
  phoneNumber?: string;
  email?: string;
}

export interface IUserPepData {
  charge: string;
  authority: string;
  character: string;
}

export enum ORDER_STATUS {
  CREATED,
  CONFIRMED,
  CANCELED,
  WAITING,
  WAITING_SELL_MEP = 12,
  SELL_MEP_EXECUTED = 13,
}

export enum ERROR {
  'CAN_OPERATE' = 0,
  'MAIL_CONFIRMATION' = 1,
  'MISS_MAIN_DATA' = 2,
  'MISS_EXTRA_DATA' = 3,
  'MISS_PEP_DATA' = 4,
  'MISS_DNI_VALIDATION' = 5,
  'BANNED_USER' = 6,
  'MISSING_FUND_ORIGIN' = 7,
  'MISS_JURIDICAL_ACCEPTANCE' = 8,
}

export enum TWOFA_STATUS {
  INACTIVE,
  PENDING_ACTIVATION,
  ACTIVE,
}

export enum DOCUMENT_STATUS {
  NOT_UPLOADED,
  PENDING,
  REJECTED,
  VALIDATED,
}
export enum DOCUMENT_ERRORS {
  REPEATED_DOCUMENTATION = 'REPEATED_DOCUMENTATION',
  DNI_STATUS = 'DNI_STATUS',
  USER_BLOCKED = 'USER_BLOCKED',
  TOO_MANY_PENDING = 'TOO_MANY_PENDING',
}
export interface IMedal {
  description: string;
  mediaData: MEDIA_DATA;
  discount: number;
  name: string;
}

export enum MEDIA_DATA {
  TIENDA_DOLAR = '0',
  FRIEND_AND_FAMILY = '1',
  MERCADO_LIBRE = '2',
}

export interface IBenefitHistory {
  _id: string;
  coin: COIN;
  creationTime: string;
  saved: number;
  source: string;
  type: BENEFIT_TYPE;
}

export enum BENEFIT_TYPE {
  COMPANIA = 0,
  REFERRAL = 1,
  REFERRAL_BOOST = 2,
}

export enum ACCOUNT_CURRENCY {
  USD = 'USD',
  ARS = 'ARS',
}

export enum ACCOUNT_ERROR {
  COIN_MISMATCH = 'COIN_MISMATCH',
  INVALID_ADDRESS = 'INVALID_ADDRESS',
  INVALID_CBU = 'INVALID_CBU',
}

export enum AUTH_ERROR {
  NOT_HUMAN = 'NOT_HUMAN',
  BAD_REQUEST = 'BAD_REQUEST',
  USER_NF = 'USER_NF',
  MAX_LOGIN_ATTEMPS = 'MAX_LOGIN_ATTEMPS',
  USER_NOT_COMMERCE = 'USER_NOT_COMMERCE',
}

export enum DOCUMENT_TYPE {
  FRONT = 'DNI_FRONT',
  BACK = 'DNI_BACK',
  BALANCE = 'BALANCE',
  ESTATUTO = 'ESTATUTO',
  FUNDS = 'FUNDS',
  IIBB = 'IIBB',
  ACTA_DESIGNACION = 'ACTA_DESIGNACION',
  ULTIMO_BALANCE_CERTIFICADO = 'ULTIMO_BALANCE_CERTIFICADO',
  CONSTANCIA_DE_CUIT = 'CONSTANCIA_DE_CUIT',
  PEP_DDJJ = 'PEP_DDJJ',
  EXTRA = 'EXTRA',
  CERTIFICACION_CONTABLE = 'CERTIFICACION_CONTABLE',
  IMPUESTO_CEDULAR = 'IMPUESTO_CEDULAR',
  SELFIE = 'SELFIE',
  DDJJ_BENEFICIARIO_FINAL = 'DDJJ_BENEFICIARIO_FINAL',
}

export enum DOCUMENT_TYPE_NAME {
  FRONT = 'Dni frente',
  BACK = 'Dni dorso',
  BALANCE = 'Último Balance',
  ESTATUTO = 'Estatuto social',
  FUNDS = 'Origen de fondo',
  IIBB = 'Constancia IIBB',
  ACTA_DESIGNACION = 'Acta de designacion de autoridades',
  ULTIMO_BALANCE_CERTIFICADO = 'Certificado de último balance',
  CONSTANCIA_DE_CUIT = 'Constancia de Cuit',
  PEP_DDJJ = 'Declaración jurada sobre la condición de personas expuestas políticamente',
  EXTRA = 'Extra',
  CERTIFICACION_CONTABLE = 'Certificación contable',
  IMPUESTO_CEDULAR = 'Impuesto cedular',
  DDJJ_BENEFICIARIO_FINAL = 'Declaración jurada sobre beneficiario final',
}

export enum BANK_NAME {
  'BANCO DE GALICIA Y BUENOS AIRES S.A.U.' = 7,
  'BANCO DE LA NACION ARGENTINA' = 11,
  'BANCO DE LA PROVINCIA DE BUENOS AIRES' = 14,
  'INDUSTRIAL AND COMMERCIAL BANK OF CHINA' = 15,
  'CITIBANK N.A.' = 16,
  'BANCO BBVA ARGENTINA S.A.' = 17,
  'BANCO DE LA PROVINCIA DE CORDOBA S.A.' = 20,
  'BANCO SUPERVIELLE S.A.' = 27,
  'BANCO DE LA CIUDAD DE BUENOS AIRES' = 29,
  'BANCO PATAGONIA S.A.' = 34,
  'BANCO HIPOTECARIO S.A.' = 44,
  'BANCO DE SAN JUAN S.A.' = 45,
  'BANCO MUNICIPAL DE ROSARIO' = 65,
  'BANCO SANTANDER RIO S.A.' = 72,
  'BANCO DEL CHUBUT S.A.' = 83,
  'BANCO DE SANTA CRUZ S.A.' = 86,
  'BANCO DE LA PAMPA SOCIEDAD DE ECONOMÍA M' = 93,
  'BANCO DE CORRIENTES S.A.' = 94,
  'BANCO PROVINCIA DEL NEUQUÉN SOCIEDAD ANÓ' = 97,
  'BRUBANK S.A.U.' = 143,
  'BANCO INTERFINANZAS S.A.' = 147,
  'HSBC BANK ARGENTINA S.A.' = 150,
  'OPEN BANK ARGENTINA S.A.' = 158,
  'JPMORGAN CHASE BANK, NATIONAL ASSOCIATIO' = 165,
  'BANCO CREDICOOP COOPERATIVO LIMITADO' = 191,
  'BANCO DE VALORES S.A.' = 198,
  'BANCO ROELA S.A.' = 247,
  'BANCO MARIVA S.A.' = 254,
  'BANCO ITAU ARGENTINA S.A.' = 259,
  'BNP PARIBAS' = 266,
  'BANCO PROVINCIA DE TIERRA DEL FUEGO' = 268,
  'BANCO DE LA REPUBLICA ORIENTAL DEL URUGU' = 269,
  'BANCO SAENZ S.A.' = 277,
  'BANCO MERIDIAN S.A.' = 281,
  'BANCO MACRO S.A.' = 285,
  'BANCO COMAFI SOCIEDAD ANONIMA' = 299,
  'BANCO DE INVERSION Y COMERCIO EXTERIOR S' = 300,
  'BANCO PIANO S.A.' = 301,
  'BANCO JULIO SOCIEDAD ANONIMA' = 305,
  'BANCO RIOJA SOCIEDAD ANONIMA UNIPERSONAL' = 309,
  'BANCO DEL SOL S.A.' = 310,
  'NUEVO BANCO DEL CHACO S. A.' = 311,
  'BANCO VOII S.A.' = 312,
  'BANCO DE FORMOSA S.A.' = 315,
  'BANCO CMF S.A.' = 319,
  'BANCO DE SANTIAGO DEL ESTERO S.A.' = 321,
  'BANCO INDUSTRIAL S.A.' = 322,
  'NUEVO BANCO DE SANTA FE SOCIEDAD ANONIMA' = 330,
  'BANCO CETELEM ARGENTINA S.A.' = 331,
  'BANCO DE SERVICIOS FINANCIEROS S.A.' = 332,
  'BANCO BRADESCO ARGENTINA S.A.U.' = 336,
  'BANCO DE SERVICIOS Y TRANSACCIONES S.A.' = 338,
  'RCI BANQUE S.A.' = 339,
  'BACS BANCO DE CREDITO Y SECURITIZACION S' = 340,
  'BANCO MASVENTAS S.A.' = 341,
  'WILOBANK S.A.' = 384,
  'NUEVO BANCO DE ENTRE RÍOS S.A.' = 386,
  'BANCO COLUMBIA S.A.' = 389,
  'BANCO BICA S.A.' = 426,
  'BANCO COINAG S.A.' = 431,
  'BANCO DE COMERCIO S.A.' = 432,
  'BANCO SUCREDITO REGIONAL S.A.U.' = 435,
  'BANCO DINO S.A.' = 448,
  'BANK OF CHINA LIMITED SUCURSAL BUENOS AI' = 515,
  'FORD CREDIT COMPAÑIA FINANCIERA S.A.' = 44059,
  'COMPAÑIA FINANCIERA ARGENTINA S.A.' = 44077,
  'VOLKSWAGEN FINANCIAL SERVICES COMPAÑIA F' = 44088,
  'IUDU COMPAÑÍA FINANCIERA S.A.' = 44090,
  'FCA COMPAÑIA FINANCIERA S.A.' = 44092,
  'GPAT COMPAÑIA FINANCIERA S.A.U.' = 44093,
  'MERCEDES-BENZ COMPAÑÍA FINANCIERA ARGENT' = 44094,
  'ROMBO COMPAÑÍA FINANCIERA S.A.' = 44095,
  'JOHN DEERE CREDIT COMPAÑÍA FINANCIERA S.' = 44096,
  'PSA FINANCE ARGENTINA COMPAÑÍA FINANCIER' = 44098,
  'TOYOTA COMPAÑÍA FINANCIERA DE ARGENTINA' = 44099,
  'NARANJA DIGITAL COMPAÑÍA FINANCIERA S.A.' = 45030,
  'MONTEMAR COMPAÑIA FINANCIERA S.A.' = 45056,
  'TRANSATLANTICA COMPAÑIA FINANCIERA S.A.' = 45072,
  'CREDITO REGIONAL COMPAÑIA FINANCIERA S.A' = 65203,
}

export enum PAYMENT_SERVICE_NAME {
  'Mercado Pago' = 31,
  'Ank' = 475,
  'Bitso' = 253,
  'BKR' = 390,
  'CuentaDigital.com' = 437,
  'GOAT S.A' = 1140,
  'INVOITION' = 116,
  'Naranja X' = 147,
  'Nubi' = 291,
  'Paymovil' = 277,
  'Pluspagos (Billetera Santa Fe)' = 62,
  'Prex' = 130,
  'Propago' = 741,
  'Resimple' = 345,
  'Sysworld Digital S.A.' = 666,
  'Satoshitango' = 222,
  'TAP Billetera Virtual' = 406,
  'Ualá' = 79,
  'PagosOnline' = 1270,
  'YPF' = 1225,
  'BAMBA' = 1201,
}

export enum DEVICE_TYPE {
  iOS,
  Android,
  Web,
}
