export const tyc = `TÉRMINOS Y CONDICIONES “CRYPTO”

Los presentes términos y condiciones del servicio compra y venta de criptomonedas (los "Términos y Condiciones Crypto") son aplicables al uso del servicio ofrecido por Sixalime S.A.S, Proveedor de Servicios de Activos Virtuales (PSAV) inscripto bajo el N°21 de fecha 16 de mayo de 2024 en el Registro de Proveedores de Servicios de Activos Virtuales de CNV. Este registro es a los fines del control como Sujeto Obligado ante la Unidad de Información Financiera (UIF) y de todo otro ente regulador facultado a tal efecto, en el marco de sus competencias, y no implica licencia ni supervisión por parte de la COMISIÓN NACIONAL DE VALORES sobre la actividad realizada por el PSAV, a través del Sitio de Tienda Crypto. Cualquier Usuario que desee acceder y/o usar el Sitio o los Servicios podrá hacerlo sujetándose a los Términos y Condiciones Generales respectivos, cuyas cláusulas resultan aplicables al presente, junto con los presentes Términos y Condiciones Crypto.
Se entenderá por el término “Criptoactivos” o “Criptomoneda” a la representación digital de valor en tanto activo financiero encriptado, definido por un protocolo computacional que puede ser objeto de comercio digital y cuya funciones son las de constituir un medio de intercambio y/ o pago, y/o una unidad de cuenta, y/o una reserva de valor, y/o herramienta de inversión financiera, y/o medio de financiación, que no posee curso legal y es de carácter descentralizado, estando su valor sujeto a la variación de precios dependiendo de la oferta y demanda en los mercados.
Se entenderá por “Sitio” a la plataforma digital de Tienda Crypto, a la que se accede a través del ingreso al sitio ubicado en el URL www.tiendacrypto.com, o a la aplicación para teléfonos móviles de Tienda Crypto.

Al presionar la opción “aceptar”, el Usuario entiende que acepta los presentes Términos y Condiciones Crypto, que ya ha aceptado los Términos y Condiciones Generales de Tienda Crypto y que mantiene un vínculo directo con Sixalime S.A.S. – sociedad por acciones simplificada registrada que brinda una plataforma para la compra y venta de criptoactivos.
Asimismo, los Servicios provistos por la Sixalime S.A.S. a través del Sitio están disponibles para personas exclusivamente calificadas que comprenden los riesgos y la volatilidad de las criptomonedas.

REGISTRACIÓN DEL USUARIO

Para poder acceder a los servicios de compra y venta de criptomonedas que se ofrecen en el Sitio, es de obligatoriedad completar el formulario de registración en todos sus campos con datos válidos, el cual tiene carácter de declaración jurada. El futuro Usuario deberá completarlo con su información personal de manera exacta, precisa y verdadera ("Datos Personales") y asume el compromiso de actualizar los Datos Personales conforme resulte necesario. El Usuario presta expresa conformidad con que Tienda Crypto utilice diversos medios para identificar sus datos personales, asumiendo el Usuario la obligación de revisarlos y mantenerlos actualizados. Se deja constancia que Tienda Crypto no se responsabiliza por la certeza de los Datos Personales brindados por los Usuarios de este sitio. Los Usuarios garantizan y responden, en cualquier caso, de la veracidad, exactitud, vigencia y autenticidad de sus Datos Personales.

El Usuario accederá a su cuenta personal ("Cuenta") mediante el ingreso de su e-mail y generación de una clave de seguridad personal elegida ("Clave de Seguridad"). El Usuario se obliga a mantener la confidencialidad de su Clave de Seguridad. La Cuenta es personal, única e intransferible. El Usuario será responsable por todas las operaciones efectuadas en su Cuenta, pues el acceso a la misma está restringido al ingreso y uso de su Clave de Seguridad, de conocimiento exclusivo del Usuario. Sixalime S.A.S. se reserva el derecho de rechazar cualquier solicitud de registración o de cancelar una registración previamente aceptada, sin que esté obligado a comunicar o exponer las razones de su decisión y sin que ello genere algún derecho a indemnización o resarcimiento.

Una vez creada la Cuenta y la Clave de Seguridad, el Usuario podrá acceder y operar con su misma Cuenta tanto a los distintos servicios que Sixalime S.A.S. ofrezca en el Sitio como a los servicios y productos que Bytelime S.A.S. ofrezca en su plataforma para la compra y venta de divisas, previa aceptación del Usuario de los correspondientes Términos y Condiciones de Compra y Venta de Divisas de Bytelime S.A.S.

Las cuentas son intransferibles, no pueden cederse los derechos ni las cuentas a terceros. Las cuentas son personales de los Usuarios y todas las transacciones realizadas por la cuenta son realizadas bajo el nombre personal del Usuario titular.

El titular es responsable de mantener la seguridad de sus credenciales.

ADQUISICIÓN DE CRIPTOACTIVOS

A los fines de adquirir criptomonedas los Usuarios realizarán transferencias bancarias desde las cuentas de su titularidad pura y exclusivamente, no siendo válidos a tales fines los depósitos u otro tipo de desembolso en efectivo ni desde cuentas bancarias de terceros. Sixalime S.A.S. podrá tomar las medidas que considere necesarias para consultar y/o confirmar información sobre la cuenta bancaria de los Usuarios, y no tendrá ningún tipo de responsabilidad por cualquier incapacidad temporal o permanente del Usuario para acceder al saldo transferido a su cuenta registrada en el Sitio.  Sixalime S.A.S. podrá establecer límites mensuales y anuales al momento de ingresar dinero a su cuenta registrada en el Sitio, y Sixalime S.A.S. no responderá por los perjuicios y/o inconvenientes ocasionados al Usuario en virtud del mal uso o conflicto entre el Usuario y el medio de pago.

Al aceptar los presentes Términos y Condiciones Crypto el Usuario declara tener capacidad legal para contratar, ser mayor de edad y ser un sujeto sofisticado que conoce y acepta expresamente las fluctuaciones de las criptomonedas. El cambio final considerado para la conversión entre pesos argentinos y la criptomoneda será el especificado en el sitio www.tiendacrypto.com.ar al momento de la acreditación de los fondos. En virtud de la fluctuación de la criptomoneda ya conocida por el Usuario y la potencial demora para la conciliación de la transferencia, el Usuario reconoce y acepta que no podrá reclamar diferencias y/o daños y/o perjuicios vinculados a la fluctuación. El Usuario renuncia por medio del presente a invocar la aplicación de la teoría de la imprevisión de conformidad con lo dispuesto por el art. 1091 del Código Civil y Comercial de la Nación.

Sixalime S.A.S. no se responsabiliza por el destino de las monedas virtuales que el Usuario adquiere ni es responsable por el origen de las mismas. En caso de que se solicitara información acerca de ciertas operaciones de una cuentapor parte de un órgano competente o autoridades judicial, el Usuario autoriza a Sixalime S.A.S. a dar cumplimiento a  una eventual obligación de informar y proveer toda la información requerida por los órganos competentes. El Usuario se obliga a garantizar la lícita procedencia de fuentes legítimas del saldo transferido a su cuenta registrada en el Sitio y que toda transacción tendrá objeto lícito.

El Usuario comprende y acepta que podrá realizar las siguientes operaciones: adquirir y vender criptoactivos contra la entrega de pesos argentinos, adquirir y vender criptoactivos mediante la transferencia de dólares estadounidenses.

El Usuario acepta y reconoce que todas estas operaciones podrán estar sujetas a un cobro por servicio, comisión o costos de transacción que informe Sixalime S.A.S.

Sixalime S.A.S. se reserva el derecho a analizar el origen de los criptoactivos que el Usuario transfiera a su cuenta registrada en el Sitio, y el derecho a rechazar dicha transferencia o desconocer la legitimidad de los criptoactivos sin que sea necesario brindar al Usuario información alguna al respecto. Sixalime y Tienda Crypto no serán responsables por las consecuencias que le impliquen al Usuario la cancelación de dichas transferencia de criptoactivos ni por los daños y/o perjuicios que se deriven como consecuencia de la misma en caso de corresponder.

INCUMPLIMIENTO

En caso de incumplimientos y/o infracciones por parte del Usuario de los presentes Términos y Condiciones Crypto, Sixalime S.A.S. se encuentra facultado a devolver los fondos del Usuario a este a través de transferencia bancaria, sin perjuicio del descuento de los gastos administrativos correspondientes.

PROTOCOLOS DE MONEDA DIGITAL

Sixalime S.A.S. no tiene autoridad ni control sobre los protocolos de software que gobiernan las operaciones en criptomoneda soportadas en nuestra plataforma. En general, estos protocolos son de código abierto y cualquier persona puede usarlos, copiarlos, modificarlos y distribuirlos. Al usar los servicios de Sixalime S.A.S., el Usuario reconoce y acepta que (i) Sixalime S.A.S. no es responsable por el funcionamiento de los protocolos subyacentes y que Sixalime S.A.S. no garantiza su funcionalidad, seguridad o disponibilidad y que (ii) los protocolos están sujetos a cambios repentinos en sus reglas operativas (es el caso de las eventuales bifurcaciones o “forks”). Tales bifurcaciones pueden afectar materialmente el valor, funcionalidad y/o denominación de la moneda digital almacenada en la plataforma de Sixalime S.A.S. En el caso de ocurrir una bifurcación, el Usuario acepta que Sixalime S.A.S. puede suspender sus operaciones de forma temporaria (con o sin aviso previo) y que Sixalime S.A.S. puede, si así lo cree necesario, (a) configurar o reconfigurar sus sistemas o (b) decidir no soportar (o dejar de soportar) un protocolo alternativo, siempre y cuando el Usuario tenga la oportunidad de retirar sus fondos de la plataforma. El Usuario reconoce y acepta que Sixalime S.A.S. no asume responsabilidad alguna respecto a una rama no soportada de un protocolo alternativo, liberando de cualquier responsabilidad al respecto a Sixalime S.A.S.

"CANASTAS”
 
Estos Términos y Condiciones Crypto establecen las cláusulas que el Usuario debe
leer y aceptar antes de utilizar los contenidos y servicios del Sitio en lo relativo a la
funcionalidad   “Canastas”.  Sixalime   S.A.S.   se   reserva   el   derecho   de   facilitar   a   los
Usuarios la posibilidad de adquirir conjuntamente ciertos criptoactivos (los que podrán
incluir,   además   de   Bitcoin   y   otras   criptomonendas)   a   ser   previamente
determinados e informados por Sixalime S.A.S. 
La   funcionalidad   “Canastas”   le   permitirá   al   Usuario   aplicar   sus   fondos   de   manera
distribuida entre ciertos criptoactivos, de conformidad con lo informado en el Sitio para
cada Canasta.  Al momento de adquirir o vender dichas Canastas, el Usuario aceptará
los precios de  los respectivos criptoactivos a adquirir  o vender, según corresponda,
informados en el Sitio.
 
El Usuario comprende y acepta que sólo podrá adquirir y vender los  criptoactivos bajo
la funcionalidad “Canastas” de manera conjunta, ya sea en forma total o parcial, y no
cada   criptoactivo   en   forma   separada,   de   conformidad   con   el   procedimiento   que
establezca Sixalime S.A.S. al efecto. Asimismo, el Usuario acepta y reconoce que (i)
Sixalime S.A.S. no garantiza a los Usuarios la posibilidad de retirar de su plataforma
los   criptoactivos   adquiridos   mediante   la   funcionalidad   “Canastas”,   liberando   de
cualquier   clase   de   responsabilidad   al   respecto   a   Sixalime   S.A.S.;   y   (ii)   todas   estas
operaciones dentro de la funcionalidad “Canastas” podrán estar sujetas a un cobro por
servicio, comisión o costos de transacción que determine Sixalime S.A.S.
 
SE HACE  SABER  QUE SIXALIME S.A.S.  NO ES UNA  ENTIDAD  FINANCIERA,  NI
OFRECE   ASESORAMIENTO   FINANCIERO   O   DE   INVERSIÓN.   QUEDA
EXPRESAMENTE ESTABLECIDO, Y EL USUARIO ACEPTA, QUE SIXALIME S.A.S.
A   TRAVÉS   DEL   SITIO   NO   PROPORCIONA   NINGÚN   TIPO   DE   CONSEJO   DE
INVERSIÓN   CON   RESPECTO   A   LOS   SERVICIOS   PRESTADOS.   ASIMISMO,   SE
ACLARA  QUE SIXALIME S.A.S. NO REALIZA TAREAS  DE INTERMEDIACIÓN DE
RECURSOS   FINANCIEROS   NI   VALORES   NEGOCIABLES,   NI   TAMPOCO
GARANTIZA NINGÚN RETORNO DE INVERSIÓN A LOS USUARIOS."


OBLIGACIONES TRIBUTARIAS

Sixalime S.A.S. no es responsable de cualquier obligación fiscal que se devengue en cabeza del Usuario, siendo este el único responsable por la oportuna declaración e ingreso de los tributos que correspondieran por su operatoria.

PERFIL DE RIESGO 

Con la aceptación de estos Términos y Condiciones Crypto, el Usuario reconoce y acepta que Tienda Crypto y/o Sixalime S.A.S y/u otras sociedades comerciales que ofrezcan en el futuro servicios o productos en el Sitio quedan habilitados a recopilar el historial de operaciones realizadas por el Usuario en el Sitio, así como a solicitar ante autoridades públicas y/o entidades privadas informes de análisis de riesgo crediticio del Usuario, para la generación y evaluación de su perfil comercial y de riesgo. El Usuario tiene conocimiento y presta su formal consentimiento para que Tienda Crypto y/o Sixalime S.A.S., a fin de brindar un mejor servicio, puedan utilizar bases de datos de terceros que ayuden a establecer el mejor perfil comercial y de riesgo del Usuario.

RESPONSABILIDAD

El Usuario reconoce y acepta que utiliza el Sitio bajo su única y exclusiva responsabilidad. Cada Usuario reconoce y acepta ser el exclusivo responsable de su accionar dentro del Sitio. El Usuario reconoce y acepta que al realizar operaciones a través del Sitio lo hace bajo su propio riesgo.

EN NINGÚN CASO SIXALIME S.A.S. SERÁ RESPONSABLE POR LUCRO CESANTE, O POR CUALQUIER OTRO DAÑO Y/O PERJUICIO QUE HAYA PODIDO SUFRIR EL USUARIO DEBIDO A LAS OPERACIONES QUE SE CONCRETEN A TRAVÉS DEL SITIO, O COMO RESULTADO DE LA PIRATERÍA (HACKEOS), MANIPULACIÓN, TRANSMISIÓN DE VIRUS, OTROS ACCESOS NO AUTORIZADOS O USO DE LOS SERVICIOS, SU CUENTA O CUALQUIER INFORMACIÓN CONTENIDA EN LA MISMA, ASÍ POR LA IMPOSIBILIDAD DE USO O FALTA DE DISPONIBILIDAD DE LOS USO O FALTA DE DISPONIBILIDAD DE LOS SERVICIOS. LAS EXCLUSIONES DE RESPONSABILIDAD SE APLICARÁN HASTA EL LÍMITE MÁXIMO PERMITIDO POR LA LEY APLICABLE. LA SOCIEDAD NO SERÁ RESPONSABLE POR LA INTERACCIÓN DE LOS USUARIOS BASADAS EN LA CONFIANZA DEPOSITADA EN EL SISTEMA O EN EL SITIO.

Al aceptar estos Términos y Condiciones, el Usuario acepta y reconoce que el comercio de bienes y productos, reales o virtuales implica un riesgo significativo debido a pérdidas que se pueden producir como resultado de las fluctuaciones propias de un mercado. El Usuario acepta y reconoce que el comercio de criptomonedas es un mercado muy volátil que se basa principalmente en la confianza y que, por ello, tiene riesgos especiales no compartidos con el comercio de materias primas o bienes en un mercado tradicional. Teniendo en cuenta estas consideraciones, el Usuario acepta, reconoce y asume que pueden existir riesgos adicionales que no han sido expresamente previstos en estos Términos y Condiciones, y libera de cualquier clase de responsabilidad a Sixalime S.A.S. y Tienda Crypto por daños o pérdidas sufridas como consecuencia directa o indirecta de las características propias del comercio de cryptoactivos y su volatilidad.

Queda expresamente establecido, y el Usuario acepta que la Sixalime S.A.S., a través del Sitio, no proporciona ningún tipo de consejo de inversión en relación con los servicios prestados. Sixalime S.A.S. podrá proporcionar información sobre el precio, el rango, la volatilidad de criptomonedas y eventos que han afectado el precio de las mismas, pero esto en ningún caso se considerará asesoría financiera o de inversión y no deberá interpretarse como tal. Cualquier decisión de compra o venta de criptomonedas es decisión exclusiva del Usuario y la Sixalime S.A.S. no será responsable de cualquier pérdida sufrida como consecuencia de esa decisión.


FALLAS EN EL SITIO 

Sixalime S.A.S. no se responsabiliza por cualquier daño, perjuicio o pérdida que sufra un Usuario causados por fallas en el Sitio, sistema, en el servidor o en Internet. Los Usuarios no podrán imputarle a Sixalime S.A.S. responsabilidad alguna ni exigirle indemnizaciones, en virtud de perjuicios resultantes de dificultades técnicas o fallas en el Sitio, los sistemas o en Internet (incluyendo, sin que implique limitación, cualquier incapacidad temporal del Usuario para acceder al Sitio). Sixalime S.A.S. no garantiza el acceso y uso continuado o ininterrumpido de su Sitio. El sistema puede eventualmente no estar disponible debido a dificultades técnicas o fallas de la red, o por cualquier otra circunstancia ajena a Sixalime S.A.S.; en tales casos, se procurará restablecerlo con la mayor celeridad posible sin que por ello pueda imputársele algún tipo de responsabilidad a Sixalime S.A.S. ni a sus sociedades vinculadas o controladas.

INDEMNIDAD

El Usuario indemnizará y mantendrá indemne a Tienda Crypto y Sixalime S.A.S. y sus directivos, gerentes, representantes y empleados, por cualquier reclamo o demanda de otros Usuarios o terceros por sus actividades en el Sitio o por su incumplimiento de los Términos y Condiciones Crypto y demás políticas que se entienden incorporadas al presente o por la violación de cualesquiera leyes o derechos de terceros.


Tienda Crypto y/o Sixalime S.A.S. se reservan el derecho de revisar estos Términos y Condiciones Compra y Venta Divisas en cualquier momento, actualizando y/o modificando esta página. El Usuario deberá revisar estos Términos y Condiciones cada vez que acceda al Sitio, puesto que las mismas son obligatorias y vinculantes. Toda modificación del presente, será considerada tácitamente aceptada toda vez que el Usuario acceda al Sitio sin necesidad de confirmación expresa salvo que Tienda Crypto y/o Sixalime S.A.S. exigiera. Asimismo, debido a que ciertos servicios y contenidos ofrecidos a los Usuarios a través del Sitio pueden contener normas específicas que reglamentan, complementan y/o modifiquen los presentes Términos y Condiciones se recomienda a los Usuarios tomar conocimiento específico de ellas antes de la utilización del Sitio.

A todos los efectos legales que corresponda Sixalime S.A.S. constituye domicilio especial en la calle REPUBLICA DE LA INDIA 2781, CABA, 1425, República Argentina.
`;

export const chlTyc = `TÉRMINOS Y CONDICIONES “CRYPTO”

Los presentes términos y condiciones del servicio compra y venta de criptomonedas (los "Términos y Condiciones Crypto") son aplicables al uso del servicio ofrecido por Global API Solutions Limited. Cualquier Usuario que desee acceder y/o usar los Servicios podrá hacerlo sujetándose a los presentes Términos y Condiciones Crypto. Se entenderá por el término “Criptoactivos” o “Criptomoneda” a la representación digital de valor en tanto activo financiero encriptado, definido por un protocolo computacional que puede ser objeto de comercio digital y cuya funciones son las de constituir un medio de intercambio y/ o pago, y/o una unidad de cuenta, y/o una reserva de valor, y/o herramienta de inversión financiera, y/o medio de financiación, que no posee curso legal y es de carácter descentralizado, estando su valor sujeto a la variación de precios dependiendo de la oferta y demanda en los mercados. Se entenderá por “Sitio” a la plataforma digital, la aplicación para teléfonos móviles o a cualquier otro formato mediante el cual Global API Solutions Limited brinde sus servicios de compra y venta de criptoactivos.

Al presionar la opción “aceptar”, el Usuario entiende que acepta los presentes Términos y Condiciones Crypto y que mantiene un vínculo directo con Global API Solutions Limited –sociedad comercial registrada bajo las leyes de las Islas Vírgenes Británicas que brinda servicios de compra y venta de criptoactivos–. Asimismo, los Servicios provistos por Global API Solutions Limited a través del Sitio están disponibles para personas exclusivamente calificadas que comprenden los riesgos y la volatilidad de las criptomonedas.

REGISTRO DEL USUARIO

Para poder acceder a los servicios de compra y venta de criptomonedas que se ofrecen en el Sitio, es de obligatoriedad completar el formulario de registración en todos sus campos con datos válidos, el cual tiene carácter de declaración jurada. El futuro Usuario deberá completarlo con su información personal de manera exacta, precisa y verdadera ("Datos Personales") y asume el compromiso de actualizar los Datos Personales conforme resulte necesario. El Usuario presta expresa conformidad con que Global API Solutions Limited utilice diversos medios para identificar sus datos personales, asumiendo el Usuario la obligación de revisarlos y mantenerlos actualizados. Se deja constancia que Global API Solutions Limited no se responsabiliza por la certeza de los Datos Personales brindados por los Usuarios de este sitio. Los Usuarios garantizan y responden, en cualquier caso, de la veracidad, exactitud, vigencia y autenticidad de sus Datos Personales. Global API Solutions Limited podrá solicitar al Usuario la información y documentación complementaria que estime necesaria a su solo criterio para verificar tanto la identidad del Usuario como la licitud del origen de los fondos transferidos a su cuenta registrada en el Sitio, así como para comprobar el objeto lícito de toda transacción realizada dentro del Sitio.

El Usuario accederá a su cuenta personal ("Cuenta") mediante el ingreso de su e-mail y generación de una clave de seguridad personal elegida ("Clave de Seguridad"). El Usuario se obliga a mantener la confidencialidad de su Clave de Seguridad. La Cuenta es personal, única e intransferible. El Usuario será responsable por todas las operaciones efectuadas en su Cuenta, pues el acceso a la misma está restringido al ingreso y uso de su Clave de Seguridad, de conocimiento exclusivo del Usuario. Global API Solutions Limited se reserva el derecho de rechazar cualquier solicitud de registración o de cancelar una registración previamente aceptada, sin que esté obligado a comunicar o exponer las razones de su decisión y sin que ello genere algún derecho a indemnización o resarcimiento.

Las cuentas son intransferibles, no pueden cederse los derechos ni las cuentas a terceros. Las cuentas son personales de los Usuarios y todas las transacciones realizadas por la cuenta son realizadas bajo el nombre personal del Usuario titular.

El titular es responsable de mantener la seguridad de sus credenciales.

ADQUISICIÓN DE CRIPTOACTIVOS

A los fines de adquirir criptomonedas los Usuarios realizarán transferencias bancarias o transferencias de criptoactivos desde las cuentas de su titularidad pura y exclusivamente, no siendo válidos a tales fines los depósitos u otro tipo de desembolso en efectivo ni desde cuentas bancarias o billeteras virtuales de terceros. Global API Solutions Limited podrá tomar las medidas que considere necesarias para consultar y/o confirmar información sobre la cuenta bancaria de los Usuarios, y no tendrá ningún tipo de responsabilidad por cualquier incapacidad temporal o permanente del Usuario para acceder al saldo transferido a su cuenta registrada en el Sitio. Global API Solutions Limited podrá establecer límites mensuales y anuales al momento de ingresar dinero a su cuenta registrada en el Sitio, y Global API Solutions Limited no responderá por los perjuicios y/o inconvenientes ocasionados al Usuario en virtud del mal uso o conflicto entre el Usuario y el medio de pago.

Al aceptar los presentes Términos y Condiciones Crypto el Usuario declara tener capacidad legal para contratar, ser mayor de edad y ser un sujeto sofisticado que conoce y acepta expresamente las fluctuaciones de las criptomonedas. El cambio final considerado para la conversión entre la moneda local seleccionada y la criptomoneda correspondiente será el especificado en el Sitio al momento de la acreditación de los fondos. En virtud de la fluctuación de la criptomoneda ya conocida por el Usuario y la potencial demora para la conciliación de la transferencia, el Usuario reconoce y acepta que no podrá reclamar diferencias y/o daños y/o perjuicios vinculados a la fluctuación. 

Global API Solutions Limited no se responsabiliza por el destino de las monedas virtuales que el Usuario adquiere ni es responsable por el origen de las mismas. En caso de que se solicitara información acerca de ciertas operaciones de una cuenta por parte de un órgano competente o autoridades judicial, el Usuario autoriza a Global API Solutions Limited a dar cumplimiento a una eventual obligación de informar y proveer toda la información requerida por los órganos competentes. El Usuario se obliga a garantizar la lícita procedencia de fuentes legítimas del saldo transferido a su cuenta registrada en el Sitio y que toda transacción tendrá objeto lícito.

El Usuario acepta y reconoce que todas estas operaciones podrán estar sujetas a un cobro por servicio, comisión o costos de transacción a ser informado por Global API Solutions Limited.

Global API Solutions Limited se reserva el derecho a analizar el origen de los criptoactivos que el Usuario transfiera a su cuenta registrada en el Sitio, y el derecho a rechazar dicha transferencia o desconocer la legitimidad de los criptoactivos sin que sea necesario brindar al Usuario información alguna al respecto. Global API Solutions Limited no serán responsables por las consecuencias que le impliquen al Usuario la cancelación de dichas transferencia de criptoactivos ni por los daños y/o perjuicios que se deriven como consecuencia de la misma en caso de corresponder.

INCUMPLIMIENTO

En caso de incumplimientos y/o infracciones por parte del Usuario de los presentes Términos y Condiciones Crytpo, Global API Solutions Limited se encuentra facultado a devolver los fondos y/o criptomonedas del Usuario a este a través de transferencia bancaria o transferencia a su billetera virtual, según corresponda, sin perjuicio del descuento de los gastos administrativos correspondientes.

PROTOCOLOS DE MONEDA DIGITAL

Global API Solutions Limited no tiene autoridad ni control sobre los protocolos de software que gobiernan las operaciones en criptomonedas soportadas en nuestra plataforma. En general, estos protocolos son de código abierto y cualquier persona puede usarlos, copiarlos, modificarlos y distribuirlos. Al usar los servicios de Global API Solutions Limited, el Usuario reconoce y acepta que (i) Global API Solutions Limited no es responsable por el funcionamiento de los protocolos subyacentes y que Global API Solutions Limited no garantiza su funcionalidad, seguridad o disponibilidad y que (ii) los protocolos están sujetos a cambios repentinos en sus reglas operativas (es el caso de las eventuales bifurcaciones o “forks”). Tales bifurcaciones pueden afectar materialmente el valor, funcionalidad y/o denominación de la moneda digital almacenada en la plataforma de Global API Solutions Limited. En el caso de ocurrir una bifurcación, el Usuario acepta que Global API Solutions Limited puede suspender sus operaciones de forma temporaria (con o sin aviso previo) y que Global API Solutions Limited puede, si así lo cree necesario, (a) configurar o reconfigurar sus sistemas o (b) decidir no soportar (o dejar de soportar) un protocolo alternativo, siempre y cuando el Usuario tenga la oportunidad de retirar sus fondos de la plataforma. El Usuario reconoce y acepta que Global API Solutions Limited no asume responsabilidad alguna respecto a una rama no soportada de un protocolo alternativo, liberando de cualquier responsabilidad al respecto a Global API Solutions Limited.

SE HACE SABER QUE GLOBAL API SOLUTIONS LIMITED NO ES UNA ENTIDAD FINANCIERA, NI OFRECE ASESORAMIENTO FINANCIERO O DE INVERSIÓN. QUEDA EXPRESAMENTE ESTABLECIDO, Y EL USUARIO ACEPTA, QUE GLOBAL API SOLUTIONS LIMITED A TRAVÉS DEL SITIO NO PROPORCIONA NINGÚN TIPO DE CONSEJO DE INVERSIÓN CON RESPECTO A LOS SERVICIOS PRESTADOS. ASIMISMO, SE ACLARA QUE GLOBAL API SOLUTIONS LIMITED NO REALIZA TAREAS DE INTERMEDIACIÓN DE RECURSOS FINANCIEROS NI VALORES NEGOCIABLES, NI TAMPOCO GARANTIZA NINGÚN RETORNO DE INVERSIÓN A LOS USUARIOS.

OBLIGACIONES TRIBUTARIAS

Global API Solutions Limited no es responsable de cualquier obligación fiscal que se devengue en cabeza del Usuario, siendo este el único responsable por la oportuna declaración e ingreso de los tributos que correspondieran por su operatoria.

PERFIL DE RIESGO

Con la aceptación de estos Términos y Condiciones Crypto, el Usuario reconoce y acepta que Global API Solutions Limited y/u otras sociedades comerciales que ofrezcan en el futuro servicios o productos en el Sitio quedan habilitados a recopilar el historial de operaciones realizadas por el Usuario en el Sitio, así como a solicitar ante autoridades públicas y/o entidades privadas informes de análisis de riesgo crediticio del Usuario, para la generación y evaluación de su perfil comercial y de riesgo. 

El Usuario tiene conocimiento y presta su formal consentimiento para que Global API Solutions Limited, a fin de brindar un mejor servicio, puedan utilizar bases de datos de terceros que ayuden a establecer el mejor perfil comercial y de riesgo del Usuario.

RESPONSABILIDAD

El Usuario reconoce y acepta que utiliza el Sitio bajo su única y exclusiva responsabilidad. Cada Usuario reconoce y acepta ser el exclusivo responsable de su accionar dentro del Sitio. El Usuario reconoce y acepta que al realizar operaciones a través del Sitio lo hace bajo su propio riesgo.

EN NINGÚN CASO GLOBAL API SOLUTIONS LIMITED SERÁ RESPONSABLE POR LUCRO CESANTE, O POR CUALQUIER OTRO DAÑO Y/O PERJUICIO QUE HAYA PODIDO SUFRIR EL USUARIO DEBIDO A LAS OPERACIONES QUE SE CONCRETEN A TRAVÉS DEL SITIO, O COMO RESULTADO DE LA PIRATERÍA (HACKEOS), MANIPULACIÓN, TRANSMISIÓN DE VIRUS, OTROS ACCESOS NO AUTORIZADOS O USO DE LOS SERVICIOS, SU CUENTA O CUALQUIER INFORMACIÓN CONTENIDA EN LA MISMA, ASÍ POR LA IMPOSIBILIDAD DE USO O FALTA DE DISPONIBILIDAD DE LOS USO O FALTA DE DISPONIBILIDAD DE LOS SERVICIOS. LAS EXCLUSIONES DE RESPONSABILIDAD SE APLICARÁN HASTA EL LÍMITE MÁXIMO PERMITIDO POR LA LEY APLICABLE. LA SOCIEDAD NO SERÁ RESPONSABLE POR LA INTERACCIÓN DE LOS USUARIOS BASADAS EN LA CONFIANZA DEPOSITADA EN EL SISTEMA O EN EL SITIO.

Al aceptar estos Términos y Condiciones, el Usuario acepta y reconoce que el comercio de bienes y productos, reales o virtuales implica un riesgo significativo debido a pérdidas que se pueden producir como resultado de las fluctuaciones propias de un mercado. El Usuario acepta y reconoce que el comercio de criptomonedas es un mercado muy volátil que se basa principalmente en la confianza y que, por ello, tiene riesgos especiales no compartidos con el comercio de materias primas o bienes en un mercado tradicional. Teniendo en cuenta estas consideraciones, el Usuario acepta, reconoce y asume que pueden existir riesgos adicionales que no han sido expresamente previstos en estos Términos y Condiciones, y libera de cualquier clase de responsabilidad a Global API Solutions Limited y sus accionistas, directores, empleados y afiliadas por daños o pérdidas sufridas como consecuencia directa o indirecta de las características propias del comercio de cryptoactivos y su volatilidad.

Queda expresamente establecido, y el Usuario acepta que la Global API Solutions Limited, a través del Sitio, no proporciona ningún tipo de consejo de inversión en relación con los servicios prestados. Global API Solutions Limited podrá proporcionar información sobre el precio, el rango, la volatilidad de criptomonedas y eventos que han afectado el precio de las mismas, pero esto en ningún caso se considerará asesoría financiera o de inversión y no deberá interpretarse como tal. Cualquier decisión de compra o venta de criptomonedas es decisión exclusiva del Usuario y la Global API Solutions Limited no será responsable de cualquier pérdida sufrida como consecuencia de esa decisión.

FALLAS EN EL SITIO

Global API Solutions Limited no se responsabiliza por cualquier daño, perjuicio o pérdida que sufra un Usuario causados por fallas en el Sitio, sistema, en el servidor o en Internet. Los Usuarios no podrán imputarle a Global API Solutions Limited responsabilidad alguna ni exigirle indemnizaciones, en virtud de perjuicios resultantes de dificultades técnicas o fallas en el Sitio, los sistemas o en Internet (incluyendo, sin que implique limitación, cualquier incapacidad temporal del Usuario para acceder al Sitio). Global API Solutions Limited no garantiza el acceso y uso continuado o ininterrumpido de su Sitio. El sistema puede eventualmente no estar disponible debido a dificultades técnicas o fallas de la red, o por cualquier otra circunstancia ajena a Global API Solutions Limited; en tales casos, se procurará restablecerlo con la mayor celeridad posible sin que por ello pueda imputársele algún tipo de responsabilidad a Global API Solutions Limited ni a sus sociedades vinculadas o controladas.

INDEMNIDAD

El Usuario indemnizará y mantendrá indemne a Global API Solutions Limited y sus directivos, gerentes, representantes, empleados y afiliadas, por cualquier reclamo o demanda de otros Usuarios o terceros por sus actividades en el Sitio o por su incumplimiento de los Términos y Condiciones Crypto y demás políticas que se entienden incorporadas al presente o por la violación de cualesquiera leyes o derechos de terceros.

Global API Solutions Limited se reserva el derecho de revisar estos Términos y Condiciones Compra y Venta Divisas en cualquier momento, actualizando y/o modificando esta página. El Usuario deberá revisar estos Términos y Condiciones cada vez que acceda al Sitio, puesto que las mismas son obligatorias y vinculantes. Toda modificación del presente, será considerada tácitamente aceptada toda vez que el Usuario acceda al Sitio sin necesidad de confirmación expresa. Asimismo, debido a que ciertos servicios y contenidos ofrecidos a los Usuarios a través del Sitio pueden contener normas específicas que reglamentan, complementan y/o modifiquen los presentes Términos y Condiciones se recomienda a los Usuarios tomar conocimiento específico de ellas antes de la utilización del Sitio.`;
