import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export enum CONFIRM_DIALOG_TYPE {
  BASIC = 'BASIC',
  COUNTDOWN = 'COUNTDOWN',
}

export interface ConfirmDialogData {
  type?: CONFIRM_DIALOG_TYPE;
  content?: string;
  title: string;
  errorCode?: string;
  isError?: boolean;
  agreementText?: string;
  acceptLabel?: string;
  rejectLabel?: string;
  timeToActivate?: number;
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  timeToActivate = 0;
  agreementValue = false;

  CONFIRM_DIALOG_TYPE = CONFIRM_DIALOG_TYPE;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: ConfirmDialogData,
  ) {
    if (!data.type) data.type = CONFIRM_DIALOG_TYPE.BASIC;

    if (data.type === CONFIRM_DIALOG_TYPE.COUNTDOWN) {
      this.timeToActivate = data.timeToActivate ?? 5;
      this.countdown();
    }
  }

  countdown() {
    if (this.timeToActivate > 0) {
      const self = this;
      setTimeout(() => {
        self.timeToActivate--;
        self.countdown();
      }, 1000);
    }
  }

  getCountdown() {
    if (this.timeToActivate > 0) {
      return `en ${this.timeToActivate}`;
    } else {
      return '';
    }
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }
}
