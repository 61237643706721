import { Component, OnInit } from '@angular/core';
import { AuthService } from './pages/core/abstraction/auth.service';
import { Crisp } from 'crisp-sdk-web';
import { SeoService } from './pages/core/services/seo.service';
import { USER_TYPE } from './pages/core/models/User';
import { environment } from 'src/environments/environment';
import * as Sentry from '@sentry/angular-ivy';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  info;
  title = 'dollar-orderbook';
  showWhatsapp = false;

  constructor(public auth: AuthService, private seoService: SeoService) {}

  ngOnInit(): void {
    Crisp.configure('636454d4-fa7f-494b-a0e0-15c0485b74bc');
    Crisp.session.setSegments(['TC'], true);
    this.auth.loggedUserObs.subscribe((user) => {
      Crisp.user.setNickname(user?.name || '');
      Crisp.user.setEmail(user?.email || '');
      Crisp.user.setPhone(user?.phoneNumber || '');
      Crisp.session.setData({
        cuit: user?.cuit || '',
        platform: 'TiendaCrypto',
        userId: user?._id || '',
        numberId: user?.numberId || '',
        wallet:
          user?.type === USER_TYPE.WHITELABEL
            ? '_' + environment.accumulatorWalletOP
            : user?.cryptoAddress
            ? '_' + user?.cryptoAddress
            : '',
        whitelabelAddress: user?.whitelabelAddress ? '_' + user?.whitelabelAddress : '',
        worldcoin: user?.type === USER_TYPE.WHITELABEL ? 'Si' : 'No',
      });
      if (user) {
        Sentry.setUser({
          cuit: user?.cuit,
          whitelabelAddress: user?.whitelabelAddress,
        });
      }
    });

    Crisp.chat.onChatOpened(() => {
      this.showWhatsapp = true;
    });

    Crisp.chat.onChatClosed(() => {
      this.showWhatsapp = false;
    });

    Crisp.setSafeMode(true);

    this.seoService.createCanonicalUrl();
  }
}
