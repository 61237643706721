/* eslint-disable @typescript-eslint/naming-convention */
import {
  DOCUMENT_STATUS,
  DOCUMENT_TYPE,
  IComplianceChecks,
  IEmployee,
  USER_LABEL,
} from './../models/User';
import { IFiatDeposit, IFiatTransaction } from './../models/FiatTransaction';
import { ITransaction } from './../models/Transactions';
import { Router } from '@angular/router';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Activity } from '../models/Activity';
import { Referral } from '../models/Referral';
import { TransactionType } from '../models/Transactions';
import { User } from '../models/User';
import { ApiService } from '../services/api.service';
import { ICryptoPortfolio, IFiatStatus } from '../models/Crypto';
import { ICommerceConfig, ICommerceUserForm } from '../models/Commerce';
import { MatDialog } from '@angular/material/dialog';
import { PaginatorData } from '../pages/history/paginator/paginator.component';
import { sha256 } from 'js-sha256';
import { environment } from 'src/environments/environment';
import { Crisp } from 'crisp-sdk-web';
import { AuthSocketService } from '../services/auth-socket.service';
import { IPromotionMessage } from '../models/PromotionMessage';
import { map, take } from 'rxjs/operators';
import { ISuccessLogin } from '../../auth/models/Auth';
import { IDcaRulesDto } from '../models/Dca';
import { IGroup, ILeaderboard } from '../models/group';
import { OnboardingState } from '../../auth/models/Onboarding';
import {
  AlertDialogComponent,
  AlertDialogData,
} from 'src/app/modules/shared/components/dialogs/alert-dialog/alert-dialog.component';
import { hiddenCoins } from '../utils/coins.hidden';

@Injectable({
  providedIn: 'root',
})
export class UserStore {
  private userActivities$ = new BehaviorSubject<Activity[]>([]);
  private complianceChecks$ = new BehaviorSubject<IComplianceChecks>(null);
  private userHistories$ = new BehaviorSubject<any>({
    bundleOrders: [],
    cryptoTxs: [],
    deposits: [],
    orders: [],
    withdraws: [],
    payroll: [],
  });
  private _historyLoaded = false;
  private _historyPagination: PaginatorData = { limit: 10, page: 1 };
  private myCryptoPortfolio$ = new BehaviorSubject<ICryptoPortfolio[]>([]);
  private employees$ = new BehaviorSubject<IEmployee[]>([]);
  private loggedUser$ = new BehaviorSubject<User>(undefined);
  private isMarketClosed$ = new BehaviorSubject<boolean>(false);
  private commerceConfig$ = new BehaviorSubject<ICommerceConfig>(null);
  private fiatStatus$ = new BehaviorSubject<IFiatStatus[]>([]);
  private dcaRules$ = new BehaviorSubject<IDcaRulesDto>(null);
  private group$ = new BehaviorSubject<IGroup>(null);
  private leaderboard$ = new BehaviorSubject<ILeaderboard[]>(null);

  private _loggedUser: User;
  private _referralBenefitAlreadyApplied = false;

  private _referral: Referral;
  private onboardingState$ = new BehaviorSubject<OnboardingState>(undefined);

  constructor(
    private apiService: ApiService,
    private router: Router,
    private dialog: MatDialog,
    private authSocketService: AuthSocketService,
  ) {
    const userLS = this.getUserFromLS();
    if (userLS) {
      this.loadUserFromLS();
    }
    const pathname = window.location.pathname;
    if (pathname.split('/')[2] !== 'w-signup') {
      this.getMe();
    }

    this.apiService.isMarketClosed().subscribe((value: boolean) => {
      this.isMarketClosed$.next(value);
    });

    this.loggedUser$.subscribe((user) => {
      if (user) {
        this.loadOnboardingDataFromLS();
      }
    });
  }

  get myCryptoPortfolio() {
    return this.myCryptoPortfolio$;
  }

  get loggedUserObs() {
    return this.loggedUser$;
  }

  get loggedUser() {
    return this._loggedUser;
  }

  set loggedUser(user) {
    this._loggedUser = user;
  }

  set referral(ref) {
    this._referral = ref;
  }

  get referral(): Referral {
    return this._referral;
  }

  public set complianceChecks(complianceChecks) {
    this.complianceChecks$.next(complianceChecks);
  }

  public get complianceChecks() {
    return this.complianceChecks$.value;
  }

  public get referralBenefitAlreadyApplied() {
    return this._referralBenefitAlreadyApplied;
  }
  public set referralBenefitAlreadyApplied(value) {
    this._referralBenefitAlreadyApplied = value;
  }

  get employees() {
    return this.employees$;
  }

  get isMarketClosed() {
    return this.isMarketClosed$;
  }

  getUserFromLS(): User | null {
    const userLS = localStorage.getItem('arugulaUserData');
    if (!userLS) return null;
    const user = JSON.parse(userLS);

    const requiredKeys = [
      '_id',
      'dnis',
      'flags',
      'bankAccounts',
      'security',
      'email',
      'cuit',
      'data',
      'phoneNumber',
      'name',
      'numberId',
      'searchName',
      'cryptoAddress',
      'cryptoAccounts',
      'fundsDocuments',
      'isCommerce',
      'balance',
      'work',
    ];

    const userLSKeys = Object.keys(user);
    const pathname = window.location.pathname;
    if (
      requiredKeys.some((requiredKey) => !userLSKeys.includes(requiredKey)) &&
      pathname.split('/')[2] !== 'signup'
    )
      return null;

    return user;
  }

  loadUserFromLS() {
    this._loggedUser = this.getUserFromLS();

    if (!localStorage.getItem('JWT_TOKEN')) {
      this.saveTokens({
        jwtToken: this._loggedUser.accessToken,
        refreshToken: this._loggedUser.refreshToken,
      });
    }
    this.loadOnboardingDataFromLS();
    this.loggedUser$.next(this._loggedUser);
  }

  loadOnboardingDataFromLS() {
    const onboardingData = localStorage.getItem('onboarding-data');
    if (onboardingData) {
      const data = JSON.parse(onboardingData);
      if (data.userId === this.loggedUser?.whitelabelId) {
        this.onboardingState.next(data);
      }
    }
  }

  get userActivities() {
    return this.userActivities$;
  }

  get userHistories() {
    return this.userHistories$;
  }

  get historyLoaded() {
    return this._historyLoaded;
  }

  set historyLoaded(arg) {
    this._historyLoaded = arg;
  }

  get historyPagination() {
    return this._historyPagination;
  }

  set historyPagination(data: PaginatorData) {
    this._historyPagination = data;
  }

  get commerceConfig() {
    return this.commerceConfig$;
  }

  get dcaRules() {
    return this.dcaRules$;
  }

  get group() {
    return this.group$;
  }

  get leaderboard() {
    return this.leaderboard$;
  }

  get onboardingState() {
    return this.onboardingState$;
  }

  // getReferralBenefits() {
  //   this.apiService.getUserBenefit().subscribe(
  //     (data) => {
  //       this.loggedUser.referralData = data.referral;
  //       this.loggedUser.companyData = data.company;
  //     },
  //     (err) => {
  //       console.log(err);
  //     },
  //   );
  // }

  patchOnboardingState(data: OnboardingState) {
    this.onboardingState.next({ ...this.onboardingState.value, ...data });
  }

  sendTikTokPageView(url: string) {
    let body = {
      event: 'ViewContent',
      event_id: `${Date.now()}`,
      timestamp: new Date().toISOString(),
      context: {
        page: {
          url: url,
        },
        user: {
          email: this.loggedUser?.email ? sha256(this.loggedUser.email) : '',
        },
        ip: '190.190.27.177',
        user_agent: window.navigator.userAgent,
      },
    };
    if (!environment.production) {
      body['test_event_code'] = 'TEST01719';
    }
    return this.apiService.sendTikTokEvent(body);
  }

  sendTikTokEvent(
    url: string,
    quantity: number,
    currency: string,
    value: number,
    price: number,
    description: string,
  ) {
    let body = {
      event: 'CompletePayment',
      event_id: `${Date.now()}`,
      timestamp: new Date().toISOString(),
      context: {
        page: {
          url: url,
        },
        user: {
          ip: '190.190.27.177',
          user_agent: window.navigator.userAgent,
          email: sha256(this.loggedUser.email),
        },
      },
      properties: {
        contents: [
          {
            price: price,
            quantity: quantity,
            content_type: 'poduct',
          },
        ],
        value: value,
        description: description,
      },
    };
    if (currency !== '') {
      body.properties['currency'] = currency;
    }
    if (!environment.production) {
      body['test_event_code'] = 'TEST01719';
    }
    return this.apiService.sendTikTokEvent(body);
  }

  getMe() {
    if (!this.getTokens().jwtToken) {
      this.loggedUser = undefined;
      return;
    }
    this.apiService.getMe().subscribe(
      (user) => {
        const auxTkn = this.loggedUser?.accessToken;
        if (!user.cryptoAccounts) {
          user.cryptoAccounts = [];
        }

        if (user.balance && !user.balance?.bundle) {
          user.balance.bundle = {
            ARS: {},
            USD: {},
          };
        }

        if (!user.fundsDocuments) {
          user.fundsDocuments = {
            funds: DOCUMENT_STATUS.NOT_UPLOADED,
            certificacionContable: DOCUMENT_STATUS.NOT_UPLOADED,
            impuestoCedular: DOCUMENT_STATUS.NOT_UPLOADED,
          };
        }

        //Ocultar coins de su balance
        if (user.balance?.crypto) {
          for (let coin of Object.keys(user.balance?.crypto)) {
            if (hiddenCoins.includes(coin)) {
              delete user.balance?.crypto?.[coin];
            }
          }
        }

        this.loggedUser = user;
        //FORZAR ONBOARDING
        // this.loggedUser.cuit = ''; //DATA CONFIRM
        // this.loggedUser.name = ''; //DATA CONFIRM
        // this.loggedUser.work.desc = '-'; //DATA EXTRA
        // this.loggedUser.data.isPEP = undefined; //PEP-INFO
        // this.loggedUser.data.rep = 'asd'; //PEP-INFO
        // this.loggedUser.bankAccounts.ARS = []; //CUENTAS
        // this.loggedUser.dnis = { back: 0, front: 0 }; //ID PHOTO

        this.loggedUser$.next(user);
        this.loggedUser.accessToken = auxTkn;

        if (!this.loggedUser.cuit) {
          this.loggedUser.cuit = '';
        }
        // if (!this.loggedUser.flags.validatedDocuments) {
        //   this.loggedUser.flags.validatedDocuments = true;
        // }
        if (!this.loggedUser.security) {
          this.loggedUser.security.twoFA = { status: 1 };
        }

        // HARCODED
        // this.loggedUser.dnis = {
        //   front: {
        //     status: 1,
        //   },
        //   back: {
        //     status: 1,
        //   },
        // };

        // if (this.loggedUser.bankAccounts.ARS.length === 0) {
        //   this.loggedUser.bankAccounts.ARS.push({
        //     description: 'Banco Harcoded',
        //     cbu: '123123123123123123',
        //     alias: 'hardcodebank',
        //   });
        // }

        this.loggedUser.activities = [];
        this.sortActivitiesAndOrders();

        this.saveUserToLocalStorage();

        // this.getReferral();
        if (!this.loggedUser.benefitHistory) {
          this.loggedUser.benefitHistory = [];
        }
        this.referralBenefitAlreadyApplied =
          user.benefitHistory.filter((his) => his.type === 1).length !== 0;

        if (
          this.loggedUser.cuit &&
          (this.loggedUser?.cuit?.startsWith('30') ||
            this.loggedUser?.cuit?.startsWith('33') ||
            this.loggedUser?.cuit?.startsWith('34'))
        ) {
          this.getEmployees();
        }
        if (this.loggedUser.isCommerce) {
          this.getCommerceConfig();
        }
        const pathname = window.location.pathname;
        if (pathname.split('/')[2] !== 'w-signup') {
          this.getDcaRules();
        }
      },
      (err: HttpErrorResponse) => {
        this.loggedUser = undefined;
        console.log('error getMe ', err);
        if (err.status === 404 && err.error === 'User not found.') {
          const pathname = window.location.pathname;
          if (pathname.split('/')[2] !== 'w-signup') {
            this.logout();
          }
        }
      },
    );
  }

  confirmMail(code: string) {
    const obs = this.apiService.confirmMail(code);
    return obs;
  }

  saveTokens(tokens: { jwtToken?: string; refreshToken?: string }) {
    if (tokens.jwtToken) {
      localStorage.setItem('JWT_TOKEN', tokens.jwtToken);
    }
    if (tokens.refreshToken) {
      localStorage.setItem('REFRESH_TOKEN', tokens.refreshToken);
    }
  }

  getTokens(): { jwtToken: string; refreshToken: string } {
    const tokens = { jwtToken: '', refreshToken: '' };
    tokens.jwtToken = localStorage.getItem('JWT_TOKEN');
    tokens.refreshToken = localStorage.getItem('REFRESH_TOKEN');
    return tokens;
  }

  saveUserToLocalStorage() {
    localStorage.setItem('arugulaUserData', JSON.stringify(this.loggedUser));
  }

  logout() {
    // TODO: Clean every store

    // this.loadingS.isLoading = false;
    // this.authService.logout();
    // this.data.logout();
    // this.data.notificationsSrc.next([]);
    // this.router.navigate(['']);\
    // this.myPortfolioSrc.next([]);
    // this.investmentBalance = 0;
    // this.loadingS.isLoading = false;
    // localStorage.removeItem('acceptAlycSecond');
    // localStorage.removeItem('acceptAlycCEDEARs');
    // localStorage.removeItem('alertMessageViewedDate');
    // this.authService.logout();
    // this.data.logout();
    // this.data.notificationsSrc.next([]);
    // localStorage.removeItem("loggedUserTiendaDollar");
    // localStorage.removeItem("loggedUserTiendaDollarV2");
    // if (localStorage.getItem("acceptAlycSecond")) localStorage.removeItem("acceptAlycSecond");
    // if (localStorage.getItem("acceptSolidario")) localStorage.removeItem("acceptSolidario");
    // localStorage.removeItem(this.JWT_TOKEN);
    // localStorage.removeItem(this.REFRESH_TOKEN);
    this.authSocketService.disconnect();
    Crisp.session?.reset();
    this.router.navigate(['/auth', 'signin']);
    this.userActivities$.next([]);
    this.myCryptoPortfolio.next([]);
    this.userHistories$.next({});
    this.employees$.next([]);
    this.group$.next(null);
    this.leaderboard$.next(null);
    localStorage.removeItem('arugulaUserData');
    localStorage.removeItem('JWT_TOKEN');
    localStorage.removeItem('REFRESH_TOKEN');
    this.loggedUser = undefined;
    this.historyLoaded = false;
    // Clean storages
  }

  logoutWLD() {
    this.authSocketService.disconnect();
    this.userActivities$.next([]);
    this.myCryptoPortfolio.next([]);
    this.userHistories$.next({});
    this.employees$.next([]);
    this.group$.next(null);
    this.leaderboard$.next(null);
    localStorage.removeItem('arugulaUserData');
    localStorage.removeItem('JWT_TOKEN');
    localStorage.removeItem('REFRESH_TOKEN');
    localStorage.removeItem('onboarding-data');
    this.loggedUser = undefined;
    this.historyLoaded = false;
  }

  verifyLogin2FA(
    apiUrl: string,
    token: string,
  ): Observable<{ body: ISuccessLogin; status: number } | HttpResponse<{}> | Error> {
    const obs = this.apiService.verifyLogin2FA(apiUrl, token);
    return obs;
  }

  recoverPassword(email: string): Observable<Object> {
    const obs = this.apiService.recoverPassword(email);
    return obs;
  }

  changePassword(verificationCode: string, password: string): Observable<Object | void> {
    const obs = this.apiService.changePassword(verificationCode, password);
    return obs;
  }

  updatePassword(oldPassword: string, newPassword: string): any {
    const obs = this.apiService.updatePassword(oldPassword, newPassword);
    return obs;
  }

  getPersona(passport: string) {
    const obs = this.apiService.getPersona(passport);
    return obs;
  }

  addOrderToHistory(order) {
    const newHistories = this.userHistories.value;
    newHistories.orders.push(order);
    this.userHistories.next(newHistories);
    this.sortActivitiesAndOrders();
  }

  sortActivitiesAndOrders() {
    let activities: Activity[] = [];

    if (!this.loggedUser) return;

    if (!this.loggedUser.orders) {
      this.loggedUser.orders = [];
    }
    if (!this.loggedUser.transactions) {
      this.loggedUser.transactions = [];
    }
    if (!this.loggedUser.deposits) {
      this.loggedUser.deposits = [];
    }
    if (!this.loggedUser.withdrawRequests) {
      this.loggedUser.withdrawRequests = [];
    }

    // TRANSACTIONS
    if (this.userHistories.value.bundleOrders) {
      this.userHistories.value.bundleOrders.forEach((history) => {
        const activity = {
          ...history,
        };
        const coinArr = history.coin.split('_');
        activity.srcCoin = history.operation === 'BUY' ? coinArr[1] : coinArr[0];
        activity.destCoin = history.operation === 'BUY' ? coinArr[0] : coinArr[1];
        if (activity.srcCoin === 'LUNA') {
          activity.srcCoin = activity.srcCoin.replace('LUNA', 'LUNC');
        } else if (activity.destCoin === 'LUNA') {
          activity.destCoin = activity.destCoin.replace('LUNA', 'LUNC');
        } else if (activity.srcCoin === 'UST') {
          activity.srcCoin = activity.srcCoin.replace('UST', 'USTC');
        } else if (activity.destCoin === 'UST') {
          activity.destCoin = activity.destCoin.replace('UST', 'USTC');
        } else if (activity.srcCoin === 'LUNA2') {
          activity.srcCoin = activity.srcCoin.replace('LUNA2', 'LUNA');
        } else if (activity.destCoin === 'LUNA2') {
          activity.destCoin = activity.destCoin.replace('LUNA2', 'LUNA');
        }
        if (coinArr[1] !== 'ARS' && coinArr[1] !== 'USD') {
          activity.description = `Intercambio de ${activity.srcCoin} por ${activity.destCoin}`;
        } else {
          activity.description = `${history.operation === 'BUY' ? 'Compra' : 'Venta'} de canasta `;
        }
        activity.isBundle = true;
        activity.category = 'order';
        activities.push(activity);
      });
    }

    if (this.userHistories.value.cryptoTxs) {
      this.userHistories.value.cryptoTxs.forEach((history) => {
        if (history.coin === 'LUNA') {
          history.coin = history.coin.replace('LUNA', 'LUNC');
        } else if (history.coin === 'UST') {
          history.coin = history.coin.replace('UST', 'USTC');
        } else if (history.coin === 'LUNA2') {
          history.coin = history.coin.replace('LUNA2', 'LUNA');
        }
        const activity = {
          ...history,
        };

        // SET DESCRIPTION

        let description;
        if (history.type === TransactionType.DEPOSIT) {
          if (activity?.payment?.commerce) {
            description = `Reembolso desde ${activity?.payment?.commerce}`;
          } else {
            description = `Depósito`;
          }
        } else if (activity?.payment?.commerce) {
          description = `Pago a ${activity.payment.commerce}`;
        } else {
          description = 'Retiro';
        }

        activity.description = `${description} ${
          activity.payment && history.type !== TransactionType.DEPOSIT
            ? 'con'
            : activity?.payment?.commerce
            ? 'en'
            : 'de'
        } ${history.coin}`;
        activity.category = 'transaction';
        activities.push(activity);
      });
    }

    // ORDERS
    if (this.userHistories.value.orders) {
      this.userHistories.value.orders.forEach((history) => {
        const activity = {
          ...history,
        };
        history.coin = history.coin.replace('COMM', '');
        const coinArr = history.coin.split('_');
        activity.srcCoin = history.operation === 'BUY' ? coinArr[1] : coinArr[0];
        activity.destCoin = history.operation === 'BUY' ? coinArr[0] : coinArr[1];
        if (activity.srcCoin === 'LUNA') {
          activity.srcCoin = activity.srcCoin.replace('LUNA', 'LUNC');
        } else if (activity.destCoin === 'LUNA') {
          activity.destCoin = activity.destCoin.replace('LUNA', 'LUNC');
        } else if (activity.srcCoin === 'UST') {
          activity.srcCoin = activity.srcCoin.replace('UST', 'USTC');
        } else if (activity.destCoin === 'UST') {
          activity.destCoin = activity.destCoin.replace('UST', 'USTC');
        } else if (activity.srcCoin === 'LUNA2') {
          activity.srcCoin = activity.srcCoin.replace('LUNA2', 'LUNA');
        } else if (activity.destCoin === 'LUNA2') {
          activity.destCoin = activity.destCoin.replace('LUNA2', 'LUNA');
        }
        if (coinArr[1] !== 'ARS' && coinArr[1] !== 'USD') {
          activity.description = `Intercambio de ${activity.srcCoin} por ${activity.destCoin}`;
        } else {
          activity.description = `${history.operation === 'BUY' ? 'Compra' : 'Venta'} de ${
            history.operation === 'BUY' ? activity.destCoin : activity.srcCoin
          }`;
        }
        activity.category = 'order';
        activities.push(activity);
      });
    }

    // WITHDRAWS
    if (this.userHistories.value.withdraws) {
      this.userHistories.value.withdraws.forEach((history) => {
        if (history.coin === 'LUNA') {
          history.coin = history.coin.replace('LUNA', 'LUNC');
        } else if (history.coin === 'UST') {
          history.coin = history.coin.replace('UST', 'USTC');
        } else if (history.coin === 'LUNA2') {
          history.coin = history.coin.replace('LUNA2', 'LUNA');
        }
        const activity = {
          ...history,
        };
        activity.description = `Retiro de ${history.coin}`;
        activity.category = 'withdraw';
        activities.push(activity);
      });
    }

    // DEPOSITS
    if (this.userHistories.value.deposits) {
      this.userHistories.value.deposits.forEach((history) => {
        if (history.coin === 'LUNA') {
          history.coin = history.coin.replace('LUNA', 'LUNC');
        } else if (history.coin === 'UST') {
          history.coin = history.coin.replace('UST', 'USTC');
        } else if (history.coin === 'LUNA2') {
          history.coin = history.coin.replace('LUNA2', 'LUNA');
        }
        const activity = {
          ...history,
        };
        activity.description = `Depósito de ${history.coin}`;
        activity.category = 'deposit';
        activities.push(activity);
      });
    }

    // PAYROLL
    if (this.userHistories.value.payroll) {
      this.userHistories.value.payroll.forEach((history) => {
        const activity = {
          ...history,
        };
        activity.description = 'Pago de salario';
        activity.category = 'payroll';
        activity.coin = history.coin.toLowerCase();
        activity.type = this.isCompany() ? 1 : 0;
        activities.push(activity);
      });
    }

    activities = activities.sort(
      (a, b) => new Date(b.creationTime).getTime() - new Date(a.creationTime).getTime(),
    );

    this.userActivities.next(activities);
    this.loggedUser$.next(this.loggedUser);
  }

  hasConfirmedMail(): boolean {
    return this.loggedUser && this.loggedUser.flags.mailConfirmed;
  }

  getPromotionMessages() {
    return this.apiService.getPromotionMessages().pipe(
      map((response: { message: IPromotionMessage[] }) => {
        return response.message || [];
      }),
    );
  }

  getAccounts(coin) {
    return this.loggedUser?.bankAccounts[coin === 1 ? 'ARS' : 'USD'];
  }

  getCryptoAccounts(chain: number) {
    return this.loggedUser?.cryptoAccounts?.filter((account) => account.chain === chain);
  }

  // Discount
  getMaxAplicableDiscount() {
    // const companyData = this.loggedUser.companyData;
    const discount = 0;
    // if (
    //   companyData &&
    //   companyData.applicable &&
    //   (companyData.remainingBenefits === 'inf' || companyData.remainingBenefits > 0)
    // ) {
    //   discount = companyData.discountPerBenefit;
    // }
    return discount;
  }

  // getReferral() {
  //   this.apiService.getReferral().subscribe((referral) => {
  //     this.referral = referral;
  //   });
  // }

  addAccount(
    currency: string,
    cbu: string,
    description: string,
    bankCode?: string,
    accountType?: string,
  ) {
    return this.apiService.addAccount(currency, cbu, description, bankCode, accountType);
  }

  removeAccount(cbu: string, currency: string) {
    return this.apiService.removeAccount(cbu, currency);
  }

  addCryptoAccount(chain: number, address: string, description: string) {
    return this.apiService.addCryptoAccount(chain, address, description);
  }

  removeCryptoAccount(chain: number, address: string) {
    return this.apiService.removeCryptoAccount(chain, address);
  }

  hasValidatedDNIS() {
    return this.loggedUser.flags.validatedDocuments;
  }

  uploadImage(
    type: string,
    document: string,
    userComment: string = '',
    deviceType: number,
    isArbitrage = false,
  ) {
    const obs = this.apiService.uploadImage(type, document, userComment, deviceType, isArbitrage);
    return obs;
  }

  uploadPassportImage(image: any, type: number) {
    const obs = this.apiService.uploadPassportImage(image, type);
    return obs;
  }

  setUploadImageURLSing() {
    const obs = this.apiService.setUploadImageURLSing();
    return obs;
  }

  uploadPreSignedImageGeneric(url: string, image: any): any {
    const obs = this.apiService.uploadPreSignedImageGeneric(url, image);
    return obs;
  }

  getUserHistories(page?: number, limit?: number) {
    const obs = this.apiService.getUserHistories(page, limit);
    obs.subscribe((data) => {
      this.userHistories$.next(data);
      this.historyLoaded = true;
      this.sortActivitiesAndOrders();

      this.historyPagination = { page, limit };
    });
    return obs;
  }

  downloadHistory() {
    return this.apiService.downloadHistory();
  }

  getUserLimits() {
    const obs = this.apiService.getUserLimits();
    obs.subscribe((data) => {
      // console.log(data);
    });
    return obs;
  }

  updateTransactionToActivities(tx: ITransaction) {
    const histories = this.userHistories$.value;
    const txIndex = histories.cryptoTxs.findIndex((history) => history.id === tx.id);
    if (txIndex !== -1) {
      // Transaction already exists
      histories.cryptoTxs[txIndex].status = tx.status;
      if (histories.cryptoTxs[txIndex]?.cryptoTxs?.hash !== tx.hash) {
        histories.cryptoTxs[txIndex].hash = tx.hash;
      }
    } else {
      // Transaction not exists
      histories.cryptoTxs.push(tx);
    }
    this.userHistories$.next(histories);
    this.sortActivitiesAndOrders();
  }

  addWithdrawTxToHistory(withdraw: IFiatTransaction) {
    const newHistories = { ...this.userHistories.value };
    newHistories.withdraws.unshift(withdraw);
    this.userHistories.next(newHistories);
    this.sortActivitiesAndOrders();
  }

  updateWithdrawToActivities(withdraw: IFiatTransaction) {
    const histories = this.userHistories$.value;
    const indexFound = histories.withdraws.findIndex(
      (history: IFiatTransaction) => history._id === withdraw._id,
    );
    if (indexFound !== -1) {
      histories.withdraws[indexFound].status = withdraw.status;
    } else {
      histories.withdraws.push(withdraw);
    }
    this.userHistories$.next(histories);
    this.sortActivitiesAndOrders();
  }

  refundWithdrawBalance(withdraw: IFiatTransaction) {
    this.loggedUser.balance.fiat[withdraw.coin] =
      +this.loggedUser.balance.fiat[withdraw.coin] + +withdraw.amount;
  }

  addDepositTxToHistory(deposit: IFiatDeposit) {
    const newHistories = { ...this.userHistories.value };
    newHistories.deposits.unshift(deposit);
    this.userHistories.next(newHistories);
    this.sortActivitiesAndOrders();
    this.loggedUser.balance.fiat[deposit.coin] =
      +this.loggedUser.balance.fiat[deposit.coin] + +deposit.amount;
    this.loggedUser$.next(this.loggedUser);
    this.dialog.open<AlertDialogComponent, AlertDialogData>(AlertDialogComponent, {
      panelClass: 'modal-container',
      disableClose: true,
      data: {
        title: 'Depósito realizado',
        content: 'Ya tenés disponible tu depósito para comenzar a operar.',
      },
    });
  }

  withdrawFiat(coin: string, accountIndex: number, amount: number): any {
    const obs = this.apiService.withdraw(coin, accountIndex, amount);
    obs.subscribe((data) => {
      this.loggedUser.balance.fiat[coin] = this.loggedUser.balance.fiat[coin] - amount;

      // Update user history
      this.addWithdrawTxToHistory(data);
    });
    return obs;
  }

  setDocumentStatus(documentType: DOCUMENT_TYPE, status: DOCUMENT_STATUS) {
    if (this.loggedUser.data.documentation) {
      this.loggedUser.data.documentation[documentType] = status;
    } else if (documentType === DOCUMENT_TYPE.PEP_DDJJ) {
      this.loggedUser.data.pepDoc = status;
    } else {
      this.loggedUser.data.documentation = {
        [documentType]: status,
      };
    }
  }

  allCompanyDocumentsUpdated() {
    if (!this.loggedUser.data.documentation) {
      return false;
    }
    const neededDocs = [
      DOCUMENT_TYPE.BALANCE,
      DOCUMENT_TYPE.ESTATUTO,
      DOCUMENT_TYPE.IIBB,
      DOCUMENT_TYPE.ULTIMO_BALANCE_CERTIFICADO,
      DOCUMENT_TYPE.ACTA_DESIGNACION,
      DOCUMENT_TYPE.CONSTANCIA_DE_CUIT,
      //DOCUMENT_TYPE.DDJJ_BENEFICIARIO_FINAL,
    ];
    // Si hay alguna doc que no exista o que tiene status NOT_UPLOADED (0), retorna false;
    for (let i = 0; i < neededDocs.length; i++) {
      if (
        !this.loggedUser.data.documentation[neededDocs[i]] ||
        this.loggedUser.data.documentation[neededDocs[i]] === 0 ||
        this.loggedUser.data.documentation[neededDocs[i]] === 2
      ) {
        return false;
      }
    }
    return true;
  }

  addEmployee(cuit: string, salary: number, isArs: boolean) {
    const obs = this.apiService.addEmployee(cuit, salary, isArs);
    obs.subscribe(() => {
      this.getEmployees();
    });
    return obs;
  }

  editEmployee(cuit: string, salary: number, isArs: boolean) {
    const obs = this.apiService.editEmployee(cuit, salary, isArs);
    obs.subscribe(() => {
      this.getEmployees();
    });
    return obs;
  }

  removeEmployee(cuit: string) {
    const obs = this.apiService.removeEmployee(cuit);
    obs.subscribe(() => {
      this.getEmployees();
    });
    return obs;
  }

  getEmployees() {
    const obs = this.apiService.getEmployees();
    obs.subscribe((data) => {
      this.employees$.next(data);
    });
  }

  sendPaymentsToEmployees() {
    const obs = this.apiService.sendPaymentsToEmployees();
    obs.subscribe(() => {
      this.getEmployees();
      this.getUserHistories().subscribe();
    });
    return obs;
  }

  commerceRegister() {
    const obs = this.apiService.commerceRegister();
    obs.subscribe((res) => {
      this.loggedUser.isCommerce = true;
      this.getCommerceConfig().subscribe();
    });
    return obs;
  }

  getCommerceConfig() {
    const obs = this.apiService.getCommerceConfig();
    obs.subscribe((res) => {
      if (res) {
        this.commerceConfig$.next(res);
      }
    });
    return obs;
  }

  setCommerceConfig(newCommerceConfig: ICommerceConfig) {
    const obs = this.apiService.setCommerceConfig(newCommerceConfig);
    obs.subscribe((res) => {
      const oldConfig = this.commerceConfig$.getValue();
      this.commerceConfig$.next({ ...oldConfig, ...res });
    });
    return obs;
  }

  commerceOnboarding(userData: ICommerceUserForm) {
    return this.apiService.commerceOnboarding(userData);
  }

  setCommerceOnboarding() {
    localStorage.setItem('commerceOnboarding', 'true');
  }

  getCommerceOnboarding() {
    return localStorage.getItem('commerceOnboarding');
  }

  isCompany() {
    return (
      this.loggedUser?.cuit &&
      (this.loggedUser?.cuit?.startsWith('30') ||
        this.loggedUser?.cuit?.startsWith('33') ||
        this.loggedUser?.cuit?.startsWith('34'))
    );
  }

  changeCommercePassword(newPassword: string) {
    return this.apiService.changeCommercePassword(newPassword);
  }

  private parseFloatStringToCurrency(numberStr: string): string {
    if (!numberStr) {
      return '0';
    }
    const arr = parseFloat(numberStr).toFixed(2).split('.');
    arr[0] = arr[0]
      .split('')
      .reverse()
      .join('')
      .match(/.{1,3}/g)
      .join('.')
      .split('')
      .reverse()
      .join('');
    if (arr[1] === '00') {
      return arr[0];
    } else {
      return arr.join(',');
    }
  }

  getFiatStatus() {
    return this.fiatStatus$;
  }

  refreshFiatStatus() {
    this.apiService.getFiatStatus().subscribe((res) => {
      this.fiatStatus$.next(res);
    });
  }

  toggleRules(rule: string) {
    return this.apiService.toggleRules(rule);
  }

  addRule(body) {
    const obs = this.apiService.addRule(body);
    obs.subscribe(() => {
      this.getDcaRules();
    });
    return obs;
  }

  getDcaRules() {
    const obs = this.apiService.getDcaRules();
    obs.subscribe((res) => {
      if (res) {
        this.dcaRules$.next(res);
      }
    });
    return obs;
  }

  getStatsRule(id: string) {
    return this.apiService.getStatsRule(id);
  }

  deleteRule(id: string) {
    const obs = this.apiService.deleteRule(id);
    obs.subscribe(() => {
      this.getDcaRules();
    });
    return obs;
  }

  changeStatusRule(id: string, status: string, statusCollapse: boolean) {
    const obs = this.apiService.changeStatusRule(id, status);
    obs.subscribe((resp) => {
      this.dcaRules$.pipe(take(1)).subscribe((rules) => {
        let update = {
          rules: null,
        };
        update.rules = rules.rules.map((element) => {
          if (element.id === resp.rule.id) {
            resp.rule.statusCollapse = statusCollapse;
            return resp.rule;
          }
          return element;
        });
        this.dcaRules$.next(update);
      });
    });
    return obs;
  }

  defaultCollapse() {
    this.dcaRules$.pipe(take(1)).subscribe((resp) => {
      let defaultRules = {
        rules: null,
      };
      defaultRules.rules = resp.rules.map((element) => {
        if (element.statusCollapse) {
          element.statusCollapse = false;
        }
        return element;
      });
      this.dcaRules$.next(defaultRules);
    });
  }

  createUuid() {
    return this.apiService.createUuid();
  }
}
