<div class="container">
  <h1 class="title" *ngIf="data?.type !== 'privacy'">Términos y condiciones</h1>
  <h1 class="title" *ngIf="data?.type === 'privacy'">Política de privacidad</h1>

  <div class="info" *ngIf="data?.type !== 'privacy'">
    <markdown class="markdown" [data]="tyc"></markdown>
  </div>
  <div class="info" *ngIf="data?.type === 'privacy'">
    <markdown class="markdown" [src]="'assets/documents/privacy.md'"></markdown>
  </div>
  <div class="options">
    <button class="td-btn primary" (click)="closeDialog()">Aceptar</button>
  </div>
</div>
