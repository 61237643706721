<div class="container">
  <div class="content">
    <img class="logo" src="assets/icons/tc_logo.svg" alt="" />

    <div class="title-section">
      <h1 class="title">Verificá tu cuenta</h1>
      <h3 class="subtitle">Te enviamos un mail de confirmación a</h3>
      <p class="mail">
        {{ authService.loggedUser?.email }}
      </p>
    </div>

    <div class="info">
      <p>
        Revisá tu casilla y confirmá la cuenta haciendo click en el enlace que te enviamos. En caso
        de no encontrarlo, revisá la casilla de SPAM o contactanos vía chat.
      </p>
    </div>

    <div class="actions">
      <button
        class="td-btn primary"
        [attr.disabled]="mailSended"
        (click)="resendConfirmationEmail()"
      >
        Reenviar mail
      </button>
      <p
        class="mail-sended"
        [class.confirmed]="mailSended === true"
        [class.error]="mailSended === false"
      >
        {{
          mailSended === true
            ? '¡Enviamos el mail a tu casilla!'
            : mailSended === false
            ? 'Ya te enviamos un mail, intentalo de nuevo más tarde'
            : ''
        }}
      </p>

      <div class="options">
        <div class="option">
          <p class="text" (click)="logout()">Volver al inicio de sesión</p>
        </div>
      </div>
    </div>

    <div class="footer">
      <p class="text" (click)="openTyc()">Términos y Condiciones</p>
      <p class="text">© TiendaCrypto 2021</p>
    </div>
  </div>
</div>
