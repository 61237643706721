export const environment = {
  production: true,
  environment: 'production',
  appUrl: 'https://app.tiendacrypto.com/',
  prodAppUrl: '',
  apiUrl: 'https://alpha.tiendadolar.com.ar/api/v2/',
  tcApiUrl: 'https://api.tiendacrypto.com/v1/',
  commerceApiUrl: 'https://api-commerce.tiendacrypto.com/v1/',
  commerceCheckoutUrl: 'https://checkout.tiendacrypto.com/pos/login',
  socketUrl: 'https://sockets.tiendacrypto.com',
  ethereumTransactionBaseUrl: 'https://etherscan.io/tx/',
  binanceTransactionBaseUrl: 'https://bscscan.com/tx/',
  terraTransactionBaseUrl: 'https://finder.terra.money/classic/tx/',
  terra2TransactionBaseUrl: 'https://finder.terra.money/mainnet/tx/',
  polygonTransactionBaseUrl: 'https://polygonscan.com/tx/',
  captchaKey: '6Le5naIfAAAAAHXINLK51INzAiW6Ipo7FMNgJwuq',
  iosApp: 'tiendacrypto',
  accumulatorWalletOP: '0x0ED78ae2a0800D6395Cf9e311659323BF2822C56',
  mantecaUrl: 'https://ramp.manteca.dev/',
};
