import { ITransaction } from './../models/Transactions';
import { MatDialog } from '@angular/material/dialog';
import { BANK_NAME, DEVICE_TYPE, DOCUMENT_STATUS, DOCUMENT_TYPE } from './../models/User';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Referral } from '../models/Referral';
import { User } from '../models/User';
import { UserStore } from '../state/user.store';
import { IFiatDeposit, IFiatTransaction } from '../models/FiatTransaction';
import { PaginatorData } from '../pages/history/paginator/paginator.component';
import { ICommerceConfig, ICommerceUserForm } from '../models/Commerce';
import { BehaviorSubject, Observable } from 'rxjs';
import { ISuccessLogin } from '../../auth/models/Auth';
import { HttpResponse } from '@angular/common/http';
import { IDcaRulesDto } from '../models/Dca';
import { ONBOARDING_FLOW, OnboardingState } from '../../auth/models/Onboarding';
import {
  AlertDialogComponent,
  AlertDialogData,
} from 'src/app/modules/shared/components/dialogs/alert-dialog/alert-dialog.component';
import { environment } from 'src/environments/environment';
import { ConfirmDialogComponent } from 'src/app/modules/shared/components/dialogs/confirm-dialog/confirm-dialog.component';

enum DNI_TYPE {
  FRONT = 'front',
  BACK = 'back',
  BOTH = 'both',
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(public router: Router, private userState: UserStore, private dialog: MatDialog) {}

  // REFERRAL

  get loggedUserObs() {
    return this.userState.loggedUserObs;
  }

  get userActivities() {
    return this.userState.userActivities;
  }

  get complianceChecks() {
    return this.userState.complianceChecks;
  }

  set complianceChecks(complianceChecks) {
    this.userState.complianceChecks = complianceChecks;
  }

  get userHistories() {
    return this.userState.userHistories;
  }

  get historyLoaded() {
    return this.userState.historyLoaded;
  }

  set historyLoaded(arg) {
    this.userState.historyLoaded = arg;
  }

  get historyPagination() {
    return this.userState.historyPagination;
  }

  set historyPagination(data: PaginatorData) {
    this.userState.historyPagination = data;
  }

  get myCryptoPortfolio() {
    return this.userState.myCryptoPortfolio;
  }

  get isMarketClosed() {
    return this.userState.isMarketClosed;
  }

  get commerceConfig() {
    return this.userState.commerceConfig;
  }

  get onboardingState() {
    const obs = this.userState.onboardingState;

    obs.subscribe((data) => {
      if (data) localStorage.setItem('onboarding-data', JSON.stringify(data));
    });

    return obs;
  }

  patchOnboardingState(data: OnboardingState) {
    this.userState.patchOnboardingState(data);
  }

  // getReferralBenefits() {
  //   this.userState.getReferralBenefits();
  // }

  sendTikTokPageView(url: string) {
    return this.userState.sendTikTokPageView(url);
  }

  sendTikTokEvent(
    url: string,
    quantity: number,
    currency: string,
    value: number,
    price: number,
    description: string,
  ) {
    return this.userState.sendTikTokEvent(url, quantity, currency, value, price, description);
  }

  getMe() {
    this.userState.getMe();
  }

  confirmMail(code: string) {
    return this.userState.confirmMail(code);
  }

  addAccount(
    currency: string,
    cbu: string,
    description: string,
    bankCode?: string,
    bankType?: string,
  ) {
    const obs = this.userState.addAccount(currency, cbu, description, bankCode, bankType);
    obs.subscribe((res) => {
      this.loggedUser.bankAccounts[currency].push(res);
      this.userState.loggedUserObs.next(this.loggedUser);
      this.saveUserToLocalStorage();
    });
    return obs;
  }

  saveAccount(currency: string, cbu: string, description: string) {
    return this.userState.addAccount(currency, cbu, description);
  }

  removeAccount(cbu: string, currency: string) {
    const obs = this.userState.removeAccount(cbu, currency);
    obs.subscribe(() => {
      this.loggedUser.bankAccounts[currency] = this.loggedUser.bankAccounts[currency].filter(
        (account) => account.cbu !== cbu,
      );
      this.userState.loggedUserObs.next(this.loggedUser);
    });
    return obs;
  }

  addCryptoAccount(chain: number, address: string, description: string) {
    const obs = this.userState.addCryptoAccount(chain, address, description);
    obs.subscribe(() => {
      this.loggedUser.cryptoAccounts.push({
        description,
        address,
        chain,
      });
      this.userState.loggedUserObs.next(this.loggedUser);
    });
    return obs;
  }

  removeCryptoAccount(address: string, chain: number) {
    const obs = this.userState.removeCryptoAccount(chain, address);
    obs.subscribe(() => {
      this.loggedUser.cryptoAccounts = this.loggedUser.cryptoAccounts.filter(
        (account) => !(account.address === address && account.chain === chain),
      );
      this.userState.loggedUserObs.next(this.loggedUser);
    });
    return obs;
  }

  saveTokens(tokens: { jwtToken?: string; refreshToken?: string }) {
    this.userState.saveTokens(tokens);
  }

  getTokens(): { jwtToken: string; refreshToken: string } {
    return this.userState.getTokens();
  }

  saveUserToLocalStorage() {
    this.userState.saveUserToLocalStorage();
  }

  logout() {
    this.userState.logout();
  }

  logoutWLD() {
    this.userState.logoutWLD();
  }

  verifyLogin2FA(
    apiUrl: string,
    token: string,
  ): Observable<{ body: ISuccessLogin; status: number } | HttpResponse<{}> | Error> {
    return this.userState.verifyLogin2FA(apiUrl, token);
  }

  recoverPassword(email: string): Observable<Object> {
    return this.userState.recoverPassword(email);
  }

  changePassword(verificationCode: string, password: string): Observable<Object | void> {
    return this.userState.changePassword(verificationCode, password);
  }

  updatePassword(oldPassword: string, newPassword: string) {
    return this.userState.updatePassword(oldPassword, newPassword);
  }

  getPersona(passport: string) {
    return this.userState.getPersona(passport);
  }

  sortActivitiesAndOrders() {
    this.userState.sortActivitiesAndOrders();
  }

  checkOnboardingStatus(type: string = '') {
    if (!this.hasValidatedDNIS()) {
      if (this.haveToOuploadDNIS() || this.haveMissingData()) {
        this.dialog
          .open<ConfirmDialogComponent, AlertDialogData>(ConfirmDialogComponent, {
            panelClass: 'modal-container',
            data: {
              title:
                type === 'prode' ? 'Estás muy cerca de jugar' : 'Estás muy cerca de tus cryptos',
              content:
                type === 'prode'
                  ? `Para jugar es necesario que tu cuenta esté validada. Completá tu perfil haciendo click acá.`
                  : `Para operar es necesario que tu cuenta esté validada. Completá tu perfil haciendo click acá.`,
              acceptLabel: 'Completar datos',
            },
          })
          .afterClosed()
          .subscribe((data) => {
            if (data) {
              this.router.navigate(['auth', 'redirect']);
            }
          });
        return false;
      } else if (this.hasPendingDNIS()) {
        this.dialog.open<AlertDialogComponent, AlertDialogData>(AlertDialogComponent, {
          panelClass: 'modal-container',
          data: {
            title: 'Perfil completado',
            content:
              'En este momento estamos validando tu cuenta. Te avisaremos por email cuando completemos tu registro.',
          },
        });
        return false;
      }
    }
    return true;
  }

  //User Status related
  hasValidatedDNIS() {
    return (
      this.loggedUser?.dnis?.front === DOCUMENT_STATUS.VALIDATED &&
      this.loggedUser?.dnis?.back === DOCUMENT_STATUS.VALIDATED
    );
  }

  hasValidatedCompanyDocuments() {
    const docs = Object.values(this.loggedUser.data.documentation);
    return !docs.some((doc) => doc !== DOCUMENT_STATUS.VALIDATED);
  }

  hasValidatedDocuments() {
    if (this.isCompany()) {
      return this.hasValidatedCompanyDocuments();
    } else {
      return this.hasValidatedDNIS() && this.hasValidatedPepOrUif();
    }
  }

  hasPendingDNIS() {
    return (
      this.loggedUser?.dnis?.front === DOCUMENT_STATUS.PENDING ||
      this.loggedUser?.dnis?.back === DOCUMENT_STATUS.PENDING
    );
  }

  haveToOuploadDNIS(type: string = DNI_TYPE.BOTH) {
    const needFront =
      this.loggedUser?.dnis?.front === DOCUMENT_STATUS.NOT_UPLOADED ||
      this.loggedUser?.dnis?.front === DOCUMENT_STATUS.REJECTED;
    const needBack =
      this.loggedUser?.dnis?.back === DOCUMENT_STATUS.NOT_UPLOADED ||
      this.loggedUser?.dnis?.back === DOCUMENT_STATUS.REJECTED;
    if (type === DNI_TYPE.FRONT) {
      return needFront;
    } else if (type === DNI_TYPE.BACK) {
      return needBack;
    } else if (type === DNI_TYPE.BOTH) {
      return needFront || needBack;
    }
  }

  hasToUploadCompanyDocuments() {
    if (this.isCompany()) {
      const neededDocs = Object.values(this.loggedUser.data?.documentation || {});
      if (neededDocs.length !== 0) {
        return neededDocs.some(
          (doc) => doc !== DOCUMENT_STATUS.VALIDATED && doc !== DOCUMENT_STATUS.PENDING,
        );
      } else {
        return true;
      }
    }
    return false;
  }

  hasToUploadPepDocuments(): boolean {
    if (this.loggedUser?.data?.isPEP) {
      return (
        this.loggedUser.data.pepDoc === undefined ||
        this.loggedUser.data.pepDoc === DOCUMENT_STATUS.NOT_UPLOADED ||
        this.loggedUser.data.pepDoc === DOCUMENT_STATUS.REJECTED
      );
    }
    return false;
  }

  hasToUploadUifDocuments(): boolean {
    if (this.loggedUser?.data?.isUIF) {
      return (
        this.loggedUser.data.uifDoc === undefined ||
        this.loggedUser.data.uifDoc === DOCUMENT_STATUS.NOT_UPLOADED ||
        this.loggedUser.data.uifDoc === DOCUMENT_STATUS.REJECTED
      );
    }
    return false;
  }

  hasValidatedPepOrUif(): boolean {
    if (this.loggedUser?.data?.isPEP || this.loggedUser?.data?.isUIF) {
      if (this.loggedUser?.data?.isPEP && this.loggedUser?.data?.isUIF) {
        return (
          this.loggedUser.data.pepDoc === DOCUMENT_STATUS.VALIDATED ||
          this.loggedUser.data.uifDoc === DOCUMENT_STATUS.VALIDATED
        );
      }
      if (this.loggedUser?.data?.isUIF) {
        return this.loggedUser.data.uifDoc === DOCUMENT_STATUS.VALIDATED;
      }
      if (this.loggedUser?.data?.isPEP) {
        return this.loggedUser.data.pepDoc === DOCUMENT_STATUS.VALIDATED;
      }
    }

    return true;
  }

  hasToWaitValidation() {
    const dnis = this.loggedUser?.dnis;
    const pep = this.loggedUser?.data?.pepDoc;
    const uif = this.loggedUser?.data?.uifDoc;
    return [dnis?.back, dnis?.front, pep, uif].every((doc) => doc === DOCUMENT_STATUS.PENDING);
  }

  hasToUpdateWork() {
    return (
      (!this.loggedUser?.work?.desc || this.loggedUser?.work?.desc === '-') && !this.isCompany()
    );
  }

  haveMissingData() {
    if (this.isCompany()) {
      return (
        !this.loggedUser ||
        !this.loggedUser.cuit ||
        !this.loggedUser.phoneNumber ||
        !this.loggedUser.data?.postalCode ||
        !this.loggedUser.bankAccounts.ARS.length
      );
    } else {
      return (
        !this.loggedUser ||
        !this.loggedUser.cuit ||
        !this.loggedUser.data?.birthDate ||
        !this.loggedUser.data?.civilState ||
        !this.loggedUser.phoneNumber ||
        !this.loggedUser.data?.postalCode ||
        !this.loggedUser.bankAccounts.ARS.length ||
        !this.loggedUser.work?.desc ||
        (this.loggedUser.work.desc === '-' && this.hasToUploadDocuments())
      );
    }
  }

  needToCompletedOnboarding() {
    if (this.isCompany()) {
      return (
        this.haveMissingData() || !this.allCompanyDocumentsUpdated() || this.hasToUploadDocuments()
      );
    } else {
      return (
        this.haveMissingData() ||
        this.hasToUploadDocuments() ||
        this.hasToUploadPepDocuments() ||
        this.hasToUploadUifDocuments()
      );
    }
  }

  hasToUploadDocuments() {
    if (this.isCompany()) {
      return this.hasToUploadCompanyDocuments();
    } else {
      return this.haveToOuploadDNIS();
    }
  }

  uploadImage(
    type: string,
    document: string,
    userComment: string = '',
    deviceType: number = DEVICE_TYPE.Web,
    isArbitrage = false,
  ) {
    return this.userState.uploadImage(type, document, userComment, deviceType, isArbitrage);
  }

  hasConfirmedMail(): boolean {
    return this.userState.hasConfirmedMail();
  }

  uploadPassportImage(image: any, type: number) {
    return this.userState.uploadPassportImage(image, type);
  }

  setUploadImageURLSing() {
    return this.userState.setUploadImageURLSing();
  }

  uploadPreSignedImageGeneric(url: string, image: any): any {
    return this.userState.uploadPreSignedImageGeneric(url, image);
  }

  getPromotionMessage() {
    return this.userState.getPromotionMessages();
  }

  // Accounts
  getAccounts(coin: number) {
    return this.userState.getAccounts(coin);
  }

  // Accounts
  getCryptoAccounts(coin: number) {
    return this.userState.getCryptoAccounts(coin);
  }

  // Discount
  getMaxAplicableDiscount() {
    return this.userState.getMaxAplicableDiscount();
  }

  isCompany() {
    return this.userState.isCompany();
  }

  //History
  getUserHistories(page?: number, limit?: number) {
    return this.userState.getUserHistories(page, limit);
  }

  downloadHistory() {
    return this.userState.downloadHistory();
  }

  updateTransactionToActivities(tx: ITransaction) {
    this.userState.updateTransactionToActivities(tx);
  }

  addDepositTxToHistory(deposit: IFiatDeposit) {
    this.userState.addDepositTxToHistory(deposit);
  }

  addWithdrawTxToHistory(withdraw: IFiatTransaction) {
    this.userState.addWithdrawTxToHistory(withdraw);
  }

  addOrderToHistory(order) {
    this.userState.addOrderToHistory(order);
  }

  updateWithdrawToActivities(withdraw: IFiatTransaction) {
    this.userState.updateWithdrawToActivities(withdraw);
  }

  refundWithdrawBalance(withdraw: IFiatTransaction) {
    this.userState.refundWithdrawBalance(withdraw);
  }

  //Limits
  getUserLimits() {
    return this.userState.getUserLimits();
  }

  withdrawFiat(coin: string, accountIndex: number, amount: number): any {
    return this.userState.withdrawFiat(coin, accountIndex, amount);
  }

  setDocumentStatus(documentType: DOCUMENT_TYPE, status: DOCUMENT_STATUS) {
    this.userState.setDocumentStatus(documentType, status);
  }

  allCompanyDocumentsUpdated() {
    return this.userState.allCompanyDocumentsUpdated();
  }

  addEmployee(cuit: string, salary: number, isArs: boolean) {
    return this.userState.addEmployee(cuit, salary, isArs);
  }

  editEmployee(cuit: string, salary: number, isArs: boolean) {
    return this.userState.editEmployee(cuit, salary, isArs);
  }

  removeEmployee(cuit: string) {
    return this.userState.removeEmployee(cuit);
  }

  sendPaymentsToEmployees() {
    return this.userState.sendPaymentsToEmployees();
  }

  commerceRegister() {
    return this.userState.commerceRegister();
  }

  commerceOnboarding(userData: ICommerceUserForm) {
    return this.userState.commerceOnboarding(userData);
  }

  setCommerceOnboarding() {
    return this.userState.setCommerceOnboarding();
  }

  getCommerceOnboarding() {
    return this.userState.getCommerceOnboarding();
  }

  getCommerceConfig() {
    return this.userState.getCommerceConfig();
  }

  setCommerConfig(newCommerceConfig: ICommerceConfig) {
    return this.userState.setCommerceConfig(newCommerceConfig);
  }

  changeCommercePassword(newPassword: string) {
    return this.userState.changeCommercePassword(newPassword);
  }

  getFiatStatus() {
    return this.userState.getFiatStatus();
  }

  refreshFiatStatus() {
    return this.userState.refreshFiatStatus();
  }

  // getReferral() {
  //   return this.userState.getReferral();
  // }

  set loggedUser(user) {
    this.userState.loggedUser = user;
  }

  // Esto podría hacer referencia al store, asi logrando facade sin cambiar nada
  get loggedUser(): User {
    return this.userState.loggedUser;
  }

  get dcaRules(): BehaviorSubject<IDcaRulesDto> {
    return this.userState.dcaRules;
  }

  set referral(ref) {
    this.userState.referral = ref;
  }

  get referral(): Referral {
    return this.userState.referral;
  }

  get referralBenefitAlreadyApplied() {
    return this.userState.referralBenefitAlreadyApplied;
  }

  set referralBenefitAlreadyApplied(ref) {
    this.userState.referralBenefitAlreadyApplied = ref;
  }

  get employees() {
    return this.userState.employees;
  }

  toggleRules(rule: string) {
    return this.userState.toggleRules(rule);
  }

  addRule(body) {
    return this.userState.addRule(body);
  }

  getDcaRules() {
    return this.userState.getDcaRules();
  }

  getStatsRule(id: string) {
    return this.userState.getStatsRule(id);
  }

  deleteRule(id: string) {
    return this.userState.deleteRule(id);
  }

  changeStatusRule(id: string, status: string, statusCollapse: boolean) {
    return this.userState.changeStatusRule(id, status, statusCollapse);
  }

  defaultCollapse() {
    return this.userState.defaultCollapse();
  }

  createUuid() {
    return this.userState.createUuid();
  }
}
