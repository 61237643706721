import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { chlTyc, tyc } from './tyc';
import { AuthService } from 'src/app/pages/core/abstraction/auth.service';
import { EXCHANGE_COUNTRY } from 'src/app/pages/auth/models/Onboarding';

@Component({
  selector: 'app-tyc',
  templateUrl: './tyc.component.html',
  styleUrls: ['./tyc.component.scss'],
})
export class TycComponent implements OnInit {
  tyc = tyc;

  constructor(
    public dialogRef: MatDialogRef<TycComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private authSvc: AuthService,
  ) {
    this.authSvc.loggedUserObs.subscribe((user) => {
      if (user?.exchangeCountry === EXCHANGE_COUNTRY.CHL) this.tyc = chlTyc;
    });
  }

  ngOnInit(): void {}

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  closeDialog() {
    this.dialogRef.close(true);
  }
}
