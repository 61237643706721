import { ACTIVITY_TYPE } from './Activity';

export interface ITransaction {
  from: string;
  to: string;
  user: { name: string; cuit: string };
  amount: string;
  cost: string;
  hash: string;
  id: string;
  payment?: IChargePayment;
  creatonTime: string;
  chain: TransactionChain;
  type: TransactionType;
  status: TransactionStatus;
  coin: string;
}

export interface IChargePayment {
  commerce?: string;
  id?: string;
}

export enum TransactionType {
  DEPOSIT = 0,
  WITHDRAW = 1,
}

export enum TransactionTypeString {
  DEPOSIT = 'DEPOSIT',
  WITHDRAW = 'WITHDRAW',
}

export enum TransactionSubtype {
  REGULAR = 0,
  SMART = 1,
  BINANCE = 2,
}

export enum TransactionChain {
  ETHEREUM = 0,
  BSC = 1,
  TERRA = 2,
  TERRA2 = 3,
  POLYGON = 4
}

export enum TransactionStatus {
  AWAITING_OK = 0,
  PENDING_CONFIRMATIONS = 1,
  CONFIRMED = 2,
  CANCELLED = 3,
  GHOST = 4, // solo Deposito, button asignar
  PENDING_ADMIN = 5, // solo Retiro, button validar, cancelar
  ERROR = 6,
  POTENTIALLY_RISKY = 7,
  GHOST_CLEANED = 8,
  REFUNDED = 11,
}
