import { TycComponent } from '../../../../modules/shared/components/dialogs/tyc/tyc.component';
import { MatDialog } from '@angular/material/dialog';
import { DataLayerService } from '../../services/utils/data-layer.service';
import { Router } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { AuthService } from '../../abstraction/auth.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthSocketService } from '../../services/auth-socket.service';

@Component({
  selector: 'app-dialog-confirm-email',
  templateUrl: './confirm-email-page.component.html',
  styleUrls: ['./confirm-email-page.component.scss'],
})
export class ConfirmEmailPageComponent implements OnInit, OnDestroy {
  mailSended: boolean = null;
  constructor(
    public authService: AuthService,
    public api: ApiService,
    public route: Router,
    public data: DataLayerService,
    private authSocketService: AuthSocketService,
    private router: Router,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.authService.sendTikTokPageView(window.location.href).subscribe();
    if (this.authService.hasConfirmedMail()) {
      this.route.navigate(['/core']);
    }

    this.authSocketService.connectEmailSocket(this.authService.loggedUser?.email);
    this.authSocketService.emailSocket.on('emailConfirmed', (data) => {
      this.authService.loggedUser.flags.mailConfirmed = true;
      this.authService.saveUserToLocalStorage();
      this.router.navigate(['/core'], {
        queryParams: {
          firstTime: true,
        },
      });
    });
  }

  openTyc() {
    this.dialog.open(TycComponent, {
      panelClass: 'modal-tyc',
    });
  }

  resendConfirmationEmail() {
    this.api.resendConfirmationMail().subscribe(
      (res) => {
        this.mailSended = true;
        setTimeout(() => {
          this.mailSended = null;
        }, 180000);
      },
      (error) => {
        this.mailSended = false;
        setTimeout(() => {
          this.mailSended = null;
        }, 180000);
      },
    );
  }

  logout() {
    this.authService.logout();
  }

  ngOnDestroy(): void {
    this.authSocketService.disconnectEmailSocket();
  }
}
