import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular-ivy';
import * as Integrations from '@sentry/integrations';

Sentry.init({
  dsn: 'https://9c7d5cbc05d7ab151c379cf9bb2248e9@o4506558158471168.ingest.sentry.io/4506558160437248',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        'localhost',
        'https://api.tiendacrypto.com/v1/user',
        'https://api.tiendacrypto.com/v1/user/whitelabel',
        /^https:\/\/yourserver\.io\/api/,
      ],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
    Integrations.captureConsoleIntegration({
      levels: ['error'],
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 0.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: environment.production ? 'production' : 'testing',
  ignoreErrors: [
    'Error: Http failure response for https://api.tiendacrypto.com/v1/user: 409 OK',
    'Error: Http failure response for https://api.tiendacrypto.com/v1/user/bankAccount/ARS: 400 OK',
    'Http failure response for https://api.tiendacrypto.com/v1/user/bankAccount/ARS: 400 OK',
    'Http failure response for https://api.tiendacrypto.com/v1/user: 409 OK',
    'Http failure response for https://api.tiendacrypto.com/v1/bundle/variation: 500 OK',
    "TypeError: Cannot read properties of undefined (reading 'reset')",
    'Error: Http failure response for https://api.tiendacrypto.com/v1/settings/order/status: 401 OK',
    "TypeError: undefined is not an object (evaluating 'g.Library.crisp.client.Session.reset')",
    'Error: Invalid nickname',
    '[object Object]',
    'Http failure response for https://api.tiendacrypto.com/v1/user/compliance/work: 0 Unknown Error',
    'Http failure response for https://api.tiendacrypto.com/v1/settings/closed: 0 Unknown Error',
    'Http failure response for https://api.tiendacrypto.com/v1/settings/order/status: 401 OK',
    'Error: Http failure response for https://api.tiendacrypto.com/v1/price/userHistory?timeframe=quarterly: 401 OK',
    'Error: Http failure response for https://api.tiendacrypto.com/v1/user: 400 OK',
    'Http failure response for https://api.tiendacrypto.com/v1/user: 400 OK',
    'Http failure response for https://api.tiendacrypto.com/v1/settings/order/status: 401 OK',
    'Http failure response for https://api.tiendacrypto.com/v1/bundle/variation: 0 Unknown Error',
    'Error: Http failure response for https://api.tiendacrypto.com/v1/user/whitelabel: 0 Unknown Error',
    'Error: Http failure response for https://api.tiendacrypto.com/v1/price/userHistory?timeframe=quarterly: 500 OK',
    'Http failure response for https://api.tiendacrypto.com/v1/user/whitelabel: 0 Unknown Error',
    'Error: Http failure response for https://api.tiendacrypto.com/v1/user/limits: 401 OK',
    'Http failure response for https://api.tiendacrypto.com/v1/withdraw/fiat: 409 OK',
    'Http failure response for https://api.tiendacrypto.com/v1/user: 500 OK',
    'Http failure response for https://api.tiendacrypto.com/v1/user: 0 Unknown Error',
  ],
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
