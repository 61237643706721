import { ConfirmEmailPageComponent } from './pages/core/pages/confirm-email-page/confirm-email-page.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { DashboardGuard } from './guards/dashboard.guard';

const routes: Routes = [
  { path: 'confirm-mail', component: ConfirmEmailPageComponent },
  {
    path: 'auth',
    loadChildren: () => import('./pages/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '',
    loadChildren: () => import('./pages/core/core.module').then((m) => m.CoreModule),
    canActivate: [DashboardGuard],
  }, // no puede ser canLoad pq sino no anda el rereouteUrl
  { path: 'blog', redirectTo: '' },
  { path: '**', redirectTo: '/' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
