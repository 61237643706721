import { DatePipe } from '@angular/common';
import { BLOCKCHAINS, ICryptoPrice, NETWORKS } from '../models/Crypto';

enum OPERATION_TYPE {
  BUY = 0,
  SELL = 1,
}
export class Utils {
  public static onMobile(): boolean {
    return window.innerWidth <= 450;
  }
  public static onTablet(): boolean {
    return window.innerWidth > 450 && window.innerWidth <= 1024;
  }
  public static onDesktop(): boolean {
    return window.innerWidth > 1024;
  }

  public static formatHistory(label, history: any[], showDaily = false) {
    const datePipe = new DatePipe('es-Ar');

    const historyFormated = [
      {
        name: label,
        series: [],
      },
    ];

    const now = new Date();
    const month = now.getMonth() + 1;
    const year = now.getUTCFullYear();
    const today = now.getDate();

    if (history[0].lastDay !== null && history[0].lastDay !== undefined) {
      for (let index = 0; index < history.length; index++) {
        if (today < history[index].lastDay) {
          history[index].lastDay = new Date(
            `${month - 1}/${history[index].lastDay}/${year}`,
          ).toLocaleDateString('es-AR', { day: 'numeric', month: 'numeric' });
        } else if (
          (today > history[index].lastDay || today === history[index].lastDay) &&
          history[index].lastDay !== 0
        ) {
          history[index].lastDay = new Date(
            `${month}/${history[index].lastDay}/${year}`,
          ).toLocaleDateString('es-AR', { day: 'numeric', month: 'numeric' });
        }
      }

      history.forEach((his) => {
        if (his.price || his.value) {
          historyFormated[0].series.push({
            name: his.lastDay ? his.lastDay : his.name,
            value: his.price ? his.price : his.value,
          });
        }
      });
    }

    if (history[0].date !== null && history[0].date !== undefined) {
      for (let index = 0; index < history.length; index++) {
        if (showDaily) {
          history[index].date = datePipe.transform(history[index].date, 'HH:mm');
        } else {
          history[index].date = datePipe.transform(history[index].date, 'd MMM');
        }
      }

      history.forEach((his) => {
        if (his.price || his.value) {
          historyFormated[0].series.push({
            name: his.date ? his.date : his.name,
            value: his.price !== null || his.price !== undefined ? his.price : his.value,
          });
        }
      });
    }

    return historyFormated;
  }
  public static formatWeeklyHistory(label, history: any[]) {
    const datePipe = new DatePipe('es-Ar');

    const historyFormated = [
      {
        name: label,
        series: [],
      },
    ];

    history.forEach((his) => {
      his.date = datePipe.transform(his.date, 'd MMM - HH:mm');
      historyFormated[0].series.push({
        name: his.date,
        value: his.price,
      });
    });

    return historyFormated;
  }

  public static formatBalanceHistory(label, history: any[], showDaily = false) {
    const datePipe = new DatePipe('es-Ar');

    const historyFormated = [
      {
        name: label,
        series: [],
      },
    ];

    history.forEach((his) => {
      if (showDaily) {
        his.date = datePipe.transform(his.date, 'HH:mm');
      } else {
        his.date = datePipe.transform(his.date, 'd/MM');
      }
      historyFormated[0].series.push({
        name: his.date,
        value: his.value,
      });
    });

    return historyFormated;
  }

  public static getMinMaxHistory(series: { name: string; value: number }[]) {
    return series.reduce(
      (minmax, cur) => {
        if (cur.value < minmax.min) {
          minmax.min = cur.value;
        }
        if (cur.value > minmax.max) {
          minmax.max = cur.value;
        }
        return minmax;
      },
      { min: Number.MAX_SAFE_INTEGER, max: -1 },
    );
  }

  public static parseMaskedInput(input: string): number {
    if (!input) {
      return 0;
    }

    const isDollar = new RegExp(/^(US)/, 'i');

    if (isDollar.test(input)) {
      return parseFloat(input.slice(4).split('.').join('').split(',').join('.'));
    } else if (input.includes('$')) {
      return parseFloat(input.slice(2).split('.').join('').split(',').join('.'));
    } else {
      return parseFloat(input);
    }
    // return parseFloat(input.substr(2).split('.').join('').split(',').join('.'));
    // return parseFloat(input);
  }

  public static roundNumber(num) {
    // Redondear num
    return +(Math.round(num * 100) / 100);
  }

  public static roundDown(num) {
    // Redondear para abajo
    return Math.floor(num * 100) / 100;
  }

  public static roundDownForOperation(num) {
    // Redondear 8 decimales para operaciones
    return Math.floor(num * 100000000) / 100000000;
  }

  public static roundDownForSpecificDecimal(num: number, decimal: number) {
    return Math.floor(num * Math.pow(10, decimal)) / Math.pow(10, decimal);
  }

  public static formatHistoryByCoin(
    coin: string,
    histories: ICryptoPrice[],
    currentPrice: ICryptoPrice,
    operation: number,
  ) {
    const datePipe = new DatePipe('es-Ar');
    const historyObj = [
      {
        name: coin,
        series: [],
      },
    ];
    histories = histories.filter((his) => !isNaN(+his.buy) && !isNaN(+his.sell));
    const maxIndex = histories.length >= 30 ? 30 : histories.length;
    if (histories.length !== 0) {
      histories
        .slice(0, maxIndex)
        .reverse()
        .forEach((his) => {
          const value = operation === OPERATION_TYPE.BUY ? +his.buy : +his.sell;
          const serie = {
            name: datePipe.transform(his.timestamp, 'dd/MM hh:mm'),
            value: parseFloat(value.toFixed(2)),
          };
          historyObj[0].series.push(serie);
        });
      const serie = {
        name: datePipe.transform(new Date().getTime(), 'dd/MM hh:mm'),
        value: operation === OPERATION_TYPE.BUY ? +currentPrice.buy : +currentPrice.sell,
      };
      historyObj[0].series.push(serie);
    } else {
      const serie = {
        name: datePipe.transform(new Date().getTime(), 'dd/MM hh:mm'),
        value: operation === OPERATION_TYPE.BUY ? +currentPrice.buy : +currentPrice.sell,
      };
      historyObj[0].series.push(serie);
    }
    return historyObj;
  }

  public static getHistoryMinMax(
    history: {
      name: string;
      series: {
        name: string;
        value: number;
      }[];
    }[],
  ) {
    let min: number;
    let max: number;
    if (!history) return { min: 0, max: 0 };
    history[0]?.series.forEach((his, index) => {
      const value = his.value;
      if (index === 0) {
        min = value;
        max = value;
      } else {
        if (min > value) {
          min = value;
        }
        if (max < value) {
          max = value;
        }
      }
    });
    if (history[0].name === 'AUST_DAI') {
      return {
        min,
        max,
      };
    } else {
      return {
        min: min * 0.995,
        max,
      };
    }
  }

  public static parseNumberToCurrencyString(value: number | string) {
    const valueStr = value.toString();
    if (!valueStr) {
      return '0';
    }
    const arr = valueStr.split('.');
    if (arr.length > 1) {
      arr[1] = arr.splice(-1)[0].substr(0, 12);
    }
    if (Number(arr[0]) > 0) {
      arr[0] = arr[0]
        .split('')
        .reverse()
        .join('')
        .match(/.{1,3}/g)
        .join('.')
        .split('')
        .reverse()
        .join('');
    }
    return arr.join(',');
  }

  public static parseCurrencryToNumber(str: string | number): number {
    if (typeof str === 'number') {
      return str;
    }
    if (!str) {
      return 0;
    }
    // if (!str.includes(',')) {
    //   str += ',0';
    // }
    const arr = str.split(',');
    if (arr.length < 2 && arr[0].split('.').length === 1) {
      return Number(arr.join('.'));
    } else if (arr.length < 2 && arr[0].split('.').length > 1) {
      return Number(arr[0]);
    } else if (arr.length > 1 && arr[0].split('.').length < 2) {
      return Number(arr.join('.'));
    } else {
      return Number(str.split('.').join('').split(',').join('.'));
    }
  }

  // transform (á, é, í, ó, ú) to (a, e, i, o, u) if exists in text
  public static replaceAccents(text: string) {
    let lowerCaseText = text.toLowerCase();
    lowerCaseText = lowerCaseText.replace(new RegExp(/á/g), 'a');
    lowerCaseText = lowerCaseText.replace(new RegExp(/é/g), 'e');
    lowerCaseText = lowerCaseText.replace(new RegExp(/í/g), 'i');
    lowerCaseText = lowerCaseText.replace(new RegExp(/ó/g), 'o');
    lowerCaseText = lowerCaseText.replace(new RegExp(/ú/g), 'u');
    return lowerCaseText;
  }

  static isAndroidDevice() {
    return /(android)/i.test(navigator.userAgent);
  }

  static isIOSDevice() {
    return /iPad|iPhone|iPod|iPad Simulator|iPhone Simulator|iPod Simulator/.test(
      navigator.userAgent,
    );
  }

  static isMobile() {
    return this.isAndroidDevice() || this.isIOSDevice();
  }

  static timeToLocal(originalTime) {
    const localDate = new Date(originalTime * 1000);
    return (
      Date.UTC(
        localDate.getFullYear(),
        localDate.getMonth(),
        localDate.getDate(),
        localDate.getHours(),
        localDate.getMinutes(),
        localDate.getSeconds(),
        localDate.getMilliseconds(),
      ) / 1000
    );
  }

  static fixTimestampDate(timestamp: any, period: string): Number {
    let newDate = new Date(timestamp);
    let setDate =
      newDate.getFullYear() +
      '-' +
      (newDate.getMonth() + 1) +
      '-' +
      newDate.getDate() +
      ' ' +
      newDate.getHours() +
      ':' +
      newDate.getMinutes();

    if (period === 'hour') return this.timeToLocal(Date.parse(setDate) / 1000);
    return Date.parse(setDate) / 1000;
  }

  static getNetwork(network: NETWORKS) {
    switch (network) {
      case NETWORKS.ERC20:
        return BLOCKCHAINS.ETHEREUM;
      case NETWORKS.BSC:
        return BLOCKCHAINS.BSC;
      case NETWORKS.OPTIMISM:
        return BLOCKCHAINS.OPTIMISM;
      case NETWORKS.POLYGON:
        return BLOCKCHAINS.POLYGON;
      case NETWORKS.TERRA:
        return BLOCKCHAINS.TERRA;
      default:
        return BLOCKCHAINS.OPTIMISM;
    }
  }
}
