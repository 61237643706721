/* eslint-disable @typescript-eslint/naming-convention */
export interface ICryptoAddress {
  added: Date | string;
  address: string;
  connector: Connector;
  _id: string;
}

export interface ICryptoCoin {
  symbol: string;
  ticker: string;
  color: { domain: [string] };
  currency: number;
  price: number;
  sellPrice: number;
  buyPrice: number;
  buyDailyChange: number;
  sellDailyChange: number;
  quantity: number;
  active: boolean;
  histories: { name: string; series: any[] }[][];
  minMax: any[];
}

export interface ICryptoBalance {
  WEEZ?: {
    amount: string;
    ticker: string;
    stakeBalance: number;
  };
  DAI?: {
    amount: string;
    ticker: string;
    stakeBalance: number;
  };
  USDC?: {
    amount: string;
    ticker: string;
    stakeBalance: number;
  };
}

export interface ICryptoStake {
  activeAmount: number;
  amountPerSecond: number;
  coin: string;
  creationTime: string;
  partialAmount: number;
  pendingAmount: number;
  period: number;
  totalAccrued: number;
  updatedAt: string;
  userId: string;
}

export interface ICryptoBundle {
  name: string;
  ticker: string;
  coins: {
    ticker: string;
    percentage: number;
    isVolaile: boolean;
  }[];
}

export interface IBundleLock {
  locks: {
    COIN_TICKER: {
      code: 'string';
      price: 'string';
    };
  };
  expires: 'string';
}
export interface ICryptoBundleOperation {
  coinAmounts: {
    [coin: string]: string;
  };
  order: {
    amount: number;
    coin: string;
    creationTime: string;
    operation: string;
    orderId: string;
    price: number;
    status: number;
    user: string;
  };
}

export interface IBundleBalance {
  ticker: string;
  balance: {
    [coin: string]: { amount: string; weiAmount: string };
  };
}

export interface IBundleCoinBalance {
  ticker: string;
  name: string;
  price: number;
  amount: number;
  variation: number;
}

export interface IaUSTData {
  real: number;
  fee: number;
  multiplier: number;
  theoric: number;
}

export interface IaUSTProfit {
  dailyGain: any[];
  totalDai: number;
  totalArs: number;
  totalUsd: number;
  gainArs: number;
  gainUsd: number;
  gain: number;
}

export interface ICryptoPrice {
  ticker?: string;
  buy: string;
  sell: string;
  coin: string;
  timestamp?: string;
  variation?: {
    daily: string;
    quarterly: string;
    realtime: string;
  };
  performance?: string;
  originalValue?: {
    buy: string;
    sell: string;
  };
}
export interface ICryptoPrices {
  [coin: string]: ICryptoPrice;
}

export interface ICoinInfo {
  description: string;
  url: string;
  tags: string[];
}

export enum BLOCKCHAINS {
  ETHEREUM,
  BSC,
  TERRA,
  TERRA2,
  POLYGON,
  OPTIMISM,
}

export enum NETWORKS {
  ERC20 = 'ERC20',
  BSC = 'BSC',
  TERRA = 'TERRA',
  POLYGON = 'POLYGON',
  OPTIMISM = 'OPTIMISM',
}

export enum BLOCKCHAINS_NAMES {
  ETHEREUM = 'ETHEREUM',
  BSC = 'BSC',
  TERRA = 'TERRA',
  TERRA2 = 'TERRA2',
  POLYGON = 'POLYGON',
}

export enum COIN_TICKER {
  ADA = 'ADA',
  AUST = 'AUST',
  BNB = 'BNB',
  BTC = 'BTC',
  CAKE = 'CAKE',
  DAI = 'DAI',
  BUSD = 'BUSD',
  DOGE = 'DOGE',
  ETH = 'ETH',
  LINK = 'LINK',
  LTC = 'LTC',
  MATIC = 'MATIC',
  SOL = 'SOL',
  USDC = 'USDC',
  USDT = 'USDT',
  UST = 'UST',
  VET = 'VET',
  LUNA = 'LUNA',
  LUNC = 'LUNC',
  USTC = 'USTC',
  LUNA2 = 'LUNA2',
  NUARS = 'NUARS',
  // Package coins
  AXS = 'AXS',
  MANA = 'MANA',
  ENJ = 'ENJ',
  SAND = 'SAND',
  PSG = 'PSG',
  ATM = 'ATM',
  BAR = 'BAR',
  CITY = 'CITY',
  WLD = 'WLD',
  // Commerce coins
  USDTCOMM = 'USDTCOMM',
  USTCOMM = 'USTCOMM',
  DAICOMM = 'DAICOMM',
  BUSDCOMM = 'BUSDCOMM',
  USDCCOMM = 'USDCCOMM',
  NUARSCOMM = 'NUARSCOMM',
  SDAI = 'SDAI',
  USDL = 'USDL',
  USDCB = 'USDCB',
}
export interface ICryptoPortfolio {
  ticker: string;
  name?: string;
  price?: string;
  porcentage?: number;
  stocks: string;
  balance?: string;
  balanceArs?: string;
  stakeBalance?: number;
  totalInvested?: number;
  totalARSinvested?: number;
  capitalChange?: number;
  capitalChangeP?: number;
  histories?: any[];
}

enum Connector {
  METAMASK = 0,
  WALLETCONNECT = 1,
}

export interface IFeeEstimate {
  gasUsed: string;
  feeInRequestedCoin: string;
  feeInUSD: string;
  feeInNative: string;
  priceOfNative: string;
  wait?: string;
}

export enum COIN_COLOR {
  ADA = '#0033AD',
  AUST = '#43C043',
  BNB = '#F3BA2F',
  BUSD = '#F4B731',
  BTC = '#F7931A',
  CAKE = '#D1884F',
  DAI = '#F4B731',
  DOGE = '#BA9F33',
  ETH = '#627EEA',
  LINK = '#2A5ADA',
  LTC = '#345D9D',
  LUNA = '#F5D13E',
  LUNA2 = '#F5D13E',
  LUNC = '#F5D13E',
  MATIC = '#2BBDF7',
  SOL = '#A556E8',
  USDC = '#2775C9',
  USDT = '#26A17B',
  UST = '#3C7DD5',
  USTC = '#3C7DD5',
  VET = '#23B1F9',
  NUARS = '#99ccff',
  // Package coins
  AXS = '#014DDA',
  MANA = '#FF5B56',
  ENJ = '#89FFFC',
  SAND = '#09AFEE',
  PSG = '#015496',
  ATM = '#CB5A41',
  BAR = '#9C0941',
  CITY = '#EF3125',
}

export enum COIN_FULL_NAME {
  ADA = 'Cardano',
  AUST = 'Anchor TerraUSD',
  BNB = 'Binance Coin',
  BTC = 'Bitcoin',
  CAKE = 'PancakeSwap',
  DAI = 'Dai',
  BUSD = 'Binance USD (Suspendido)',
  DOGE = 'Dogecoin',
  ETH = 'Ethereum',
  LINK = 'Chainlink',
  LTC = 'Litecoin',
  LUNC = 'Luna Classic',
  MATIC = 'Polygon',
  SOL = 'Solana',
  USDC = 'USD Coin',
  USDT = 'Tether',
  USTC = 'TerraClassicUSD',
  VET = 'VeChain',
  LUNA = 'Luna',
  NUARS = 'Num ARS',
  // Package coins
  AXS = 'Axie Infinity',
  MANA = 'Decentraland',
  ENJ = 'Enjin Coin',
  SAND = 'The Sandbox',
  PSG = 'Paris Saint-Germain Fan Token',
  ATM = 'Atletico De Madrid Fan Token',
  BAR = 'FC Barcelona Fan Token',
  CITY = 'Manchester City Fan Token',
}

export enum HISTORY_TYPE {
  QUARTERLY = 'quarterly',
  DAILY = 'daily',
}

export enum ICON_CARD_ACCOUNT {
  eth = 'ethereum-network',
  bsc = 'binance-network',
  luna = 'terra-network',
  polygon = 'polygon-network',
}

export interface IBlockchainStatus {
  chain: number;
  type: number;
  coins: {
    [name: string]: boolean;
  };
}

export interface IFiatStatus extends IBlockchainStatus {
  coins: {
    ARS: boolean;
    USD: boolean;
  };
}

export enum CURRENCY_FILTER {
  USD,
  ARS,
}
