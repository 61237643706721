import { Injectable } from '@angular/core';
import { IAlertMessage } from '../../models/AlertMessage';
import { ApiService } from '../api.service';
import { AuthService } from '../../abstraction/auth.service';

@Injectable({
  providedIn: 'root',
})
export class DataLayerService {
  withdrawType: number;
  withdrawStep: number;

  public alertMessages: IAlertMessage;
  chatShow = false;

  public get profileUrl() {
    return `https://avatars.dicebear.com/api/identicon/${this.auth.loggedUser?.email}.svg`;
  }

  constructor(private api: ApiService, public auth: AuthService) {
    this.api.getAlertMessage().subscribe((alertMessage: IAlertMessage) => {
      this.alertMessages = alertMessage;
    });
  }
}
