import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../pages/core/abstraction/auth.service';
import { USER_TYPE } from '../pages/core/models/User';

@Injectable({
  providedIn: 'root',
})
export class DashboardGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    const pathname = window.location.pathname;
    if (this.auth.loggedUser && !this.auth.loggedUser.flags.mailConfirmed) {
      this.router.navigate(['confirm-mail']);
      return false;
    } else if (!this.auth.loggedUser) {
      this.router.navigate(['auth/signin'], { state: { routeUrl: state.url } });
      return false;
    } else if (pathname === '/payroll' && !this.auth.isCompany()) {
      this.router.navigate(['/']);
      return false;
    } else if (
      (pathname === '/commerce' || pathname === '/charge') &&
      !this.auth.loggedUser.isCommerce
    ) {
      this.router.navigate(['/']);
      return false;
    } else if (pathname === '/profile/commerce' && this.auth.loggedUser.isCommerce) {
      this.router.navigate(['profile', 'information']);
      return false;
    } else if (this.auth.loggedUser.type === USER_TYPE.WHITELABEL) {
      this.router.navigate(['auth', 'w-signup', 'data-confirm'], {
        queryParamsHandling: 'preserve',
      });
      return false;
    }
    return true;
  }
}
