import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpClient,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, share, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthSocketService } from '../services/auth-socket.service';

@Injectable({ providedIn: 'root' })
export class TokenInterceptor implements HttpInterceptor {
  isRefreshing = false;
  public JWT_TOKEN = 'JWT_TOKEN';
  public REFRESH_TOKEN = 'REFRESH_TOKEN';

  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private token = '';

  constructor(
    public router: Router,
    private http: HttpClient,
    private authSocketService: AuthSocketService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.token = localStorage.getItem(this.JWT_TOKEN);
    if (
      !request.url.includes('login') &&
      !request.url.includes('register') &&
      !request.url.includes('verifyLogin') &&
      !request.url.includes('user') &&
      !request.url.includes('price/all') &&
      !request.url.includes('bundle') &&
      !request.url.includes('closed') &&
      !request.url.includes('crossLogin')
    ) {
      request = this.addToken(request, this.token);
    }
    return next.handle(request).pipe(
      catchError((error) => {
        const pathname = window.location.pathname;
        if (error?.error?.error === 'Invalid token.' && pathname.split('/')[2] !== 'w-signup') {
          this.logout();
        }
        if (JSON.stringify(error).includes('Expired token')) {
          this.logout();
        }
        if (error.status === 401 && pathname.split('/')[2] !== 'w-signup') {
          this.handle401Error(request, next);
        }
        return throwError(error);
      }),
    );
  }

  logout() {
    localStorage.removeItem('arugulaUserData');
    localStorage.removeItem('JWT_TOKEN');
    localStorage.removeItem('REFRESH_TOKEN');
    this.authSocketService.disconnect();
    window.location.href = '/auth/signin';
  }

  saveTokens(tokens: { jwtToken?: string; refreshToken?: string }) {
    if (tokens.jwtToken) {
      localStorage.setItem(this.JWT_TOKEN, tokens.jwtToken);
    }
    if (tokens.refreshToken) {
      localStorage.setItem(this.REFRESH_TOKEN, tokens.refreshToken);
    }
  }

  refreshToken() {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    return this.http
      .post<any>(
        environment.tcApiUrl + 'auth/refresh',
        { refreshToken: localStorage.getItem(this.REFRESH_TOKEN) },
        { headers },
      )
      .pipe(share());
  }

  private addToken(request: HttpRequest<any>, token: string) {
    if (!request.headers['x-access-token']) {
      return request.clone({
        setHeaders: {
          'x-access-token': token,
        },
      });
    }
    return request.clone();
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      !request.url.includes('refresh') &&
      !request.url.includes('/auth/verify2FA') &&
      !request.url.includes('/auth/2FA?token')
    ) {
      this.logout();
    } else if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      return this.refreshToken().pipe(
        switchMap((token: { accessToken: string; refreshToken: string }) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token.refreshToken);
          this.saveTokens({ jwtToken: token.accessToken, refreshToken: token.refreshToken });
          return next.handle(this.addToken(request, token.accessToken));
        }),
      );
    }
  }
}
