import { environment } from 'src/environments/environment';
import {
  ICryptoBundle,
  ICryptoBundleOperation,
  IBundleLock,
  IBlockchainStatus,
  IFiatStatus,
} from './../models/Crypto';
import { IComplianceChecks, IUserInfoField, IUserPepData } from './../models/User';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { map, share } from 'rxjs/operators';
import { User } from '../models/User';
import {
  ICharge,
  IChargeItem,
  IChargePrice,
  IChargesResponse,
  IChargeStats,
  ICommerceConfig,
  ICommerceUserForm,
  IRules,
} from '../models/Commerce';
import { IAlertMessage } from '../models/AlertMessage';
import { ITransaction } from '../models/Transactions';
import { IOrderStatus } from '../models/Order';
import {
  IProdeConfig,
  IProdeGlobalRankingResponse,
  IProdeGroup,
  IProdeGroupRanking,
  IProdeTicket,
  IProdeTicketsResponse,
} from '../models/Prode';
import { Observable } from 'rxjs';
import { IGenerateQrResponse, ISuccessLogin } from '../../auth/models/Auth';
import { CoincapResp } from '../models/Coincap';
import { CryptoCompareData, CryptoCompareResp } from '../models/CryptoCompare';
import { Utils } from '../utils/Utils';
import { IDcaRulesDto, IRuleDto, IRuleStatsDto } from '../models/Dca';
import { IGroup, IHistoryGroup, ILeaderboard, IOrderGroup, IPriceGroup } from '../models/group';
import { WhitelabelRegisterRequest } from '../../auth/models/Onboarding';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(public http: HttpClient) {}

  getTokens(): { jwtToken: string; refreshToken: string } {
    const tokens = { jwtToken: '', refreshToken: '' };
    tokens.jwtToken = localStorage.getItem('JWT_TOKEN');
    tokens.refreshToken = localStorage.getItem('REFRESH_TOKEN');
    return tokens;
  }

  //////////////////////////////////////////////////////////
  /////////////////// ---- TC APIs ---- /////////////////////

  register(email: string, password: string, ref: string = '', prodeReferredBy: string = '') {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    return this.http
      .post<User>(
        environment.tcApiUrl + 'user',
        { email, password, ref, prodeReferredBy },
        { headers },
      )
      .pipe(share());
  }

  registerWorldCoin(data: WhitelabelRegisterRequest) {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');

    if (data.address) data.address = data.address.toLocaleLowerCase();
    if (!data.exchangeCountry) data.exchangeCountry = 'ARG';
    if (!data.address) data.address = data.userId;

    return this.http
      .post<{ accessToken: string; refreshToken: string }>(
        environment.tcApiUrl + 'user/whitelabel',
        data,
        { headers },
      )
      .pipe(share());
  }

  crossLogin(humanToken: string): Observable<IGenerateQrResponse> {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const params = new HttpParams().append('humanToken', humanToken);

    return this.http.get<IGenerateQrResponse>(environment.tcApiUrl + 'auth/crossLogin', {
      headers,
      params,
    });
  }

  login(email: string, password: string, fingerprint: string, token: string) {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    const obs = this.http
      .post<{
        accessToken: string;
        refreshToken: string;
        user: User;
        complianceChecks: IComplianceChecks;
      }>(
        environment.tcApiUrl + 'auth/login',
        { email, password, fingerprint, humanToken: token },
        { ...headers, observe: 'response' },
      )
      .pipe(share());
    return obs;
  }

  loginVtex(token: string) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    const obs = this.http
      .post<{ returnUrl: string }>(
        environment.commerceApiUrl + 'vtex/authorization/credentials?token=' + token,
        { headers },
      )
      .pipe(share());
    return obs;
  }

  getMe() {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http.get<User>(environment.tcApiUrl + 'user', { headers }).pipe(share());
  }

  confirmMail(code: string) {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    return this.http
      .post<User>(environment.tcApiUrl + 'user/confirmMail', { code }, { headers })
      .pipe(share());
  }

  updateUserInfo(fields: IUserInfoField, updatePersonalDataLastCheckFlag: boolean = false) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .patch<any>(
        environment.tcApiUrl + 'user',
        { fields, updatePersonalDataLastCheckFlag },
        { headers },
      )
      .pipe(share());
  }

  updateUserWork(desc: string) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .put<void>(environment.tcApiUrl + 'user/compliance/work', { desc }, { headers })
      .pipe(share());
  }

  updateUserPepInfo(pepData: IUserPepData) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .put<any>(
        environment.tcApiUrl + 'user/pep',
        {
          pepData,
        },
        { headers },
      )
      .pipe(share());
  }

  createMarketOrder(amount: string, coin: string, operation: string, code: string) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .post<any>(
        environment.tcApiUrl + 'order',
        {
          amount,
          coin,
          operation, // ['BUY' | 'SELL']
          code,
        },
        { headers },
      )
      .pipe(share());
  }

  getAlertMessage() {
    return this.http.get<IAlertMessage>(environment.tcApiUrl + 'settings/alerts');
  }

  getPromotionMessages() {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    return this.http.get(environment.tcApiUrl + 'settings/promotions?platform=2', { headers });
  }

  createBundleOrder(bundle: string, price: number, operation: string, lockedPrices) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .post<ICryptoBundleOperation>(
        environment.tcApiUrl + 'order/bundle',
        {
          bundle,
          price,
          operation, // ['BUY' | 'SELL']
          lockedPrices,
        },
        { headers },
      )
      .pipe(share());
  }

  lockMarketOrder(coin: string, operation: string) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .post<any>(
        environment.tcApiUrl + 'order/lock',
        {
          coin,
          operation, // ['BUY' | 'SELL']
        },
        { headers },
      )
      .pipe(share());
  }

  lockBundleOrder(coins: string[], operation: string) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .post<IBundleLock>(
        environment.tcApiUrl + 'order/lock/multiple',
        {
          coins,
          operation, // ['BUY' | 'SELL']
        },
        { headers },
      )
      .pipe(share());
  }

  getBundleMinAmount(ticker: string) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .get<{ price: number }>(environment.tcApiUrl + 'bundle/' + ticker + '/buy', { headers })
      .pipe(share());
  }

  getAllPrices() {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    return this.http.get<any>(environment.tcApiUrl + 'price/all', { headers }).pipe(share());
  }

  getPriceByCoin(coin: string) {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    return this.http
      .get<any>(environment.tcApiUrl + 'price/coin/' + coin, { headers })
      .pipe(share());
  }

  getCryptoBundles() {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    return this.http
      .get<ICryptoBundle[]>(environment.tcApiUrl + 'bundle', { headers })
      .pipe(
        map((res: any) => {
          return res.bundles as ICryptoBundle[];
        }),
      )
      .pipe(share());
  }

  getBundlesVariations() {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    return this.http
      .get<{ ticker: string; variation: number }>(environment.tcApiUrl + 'bundle/variation', {
        headers,
      })
      .pipe(share());
  }

  getHistoryByCoin(coin: string, timeframe: string = 'daily') {
    // /price/history/:coin?timeframe=DAILY o QUARTERLY
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    return this.http
      .get<any>(environment.tcApiUrl + `price/history/${coin}?timeframe=${timeframe}`, { headers })
      .pipe(share());
  }

  getAllCoinsPriceHistory(timeframe: string = 'quarterly') {
    // /price/history?timeframe=DAILY o QUARTERLY
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .get<{ [coin: string]: { coin: string; buy: string; sell: string; timestamp: string }[] }>(
        environment.tcApiUrl + `price/userHistory?timeframe=${timeframe}`,
        { headers },
      )
      .pipe(share());
  }

  downloadBundleTicket(orderId: string) {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    return this.http
      .get<{ ticker: string; ticketUrl: string }[]>(
        environment.tcApiUrl + `order/bundle/${orderId}/ticket`,
        { headers },
      )
      .pipe(share());
  }

  uploadImage(
    type: string,
    document: string,
    userComment: string = '',
    deviceType: number,
    isArbitrage = false,
  ) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .post<any>(
        environment.tcApiUrl + 'user/document',
        { type, document, userComment, deviceType, isArbitrage },
        { headers },
      )
      .pipe(share());
  }

  getUserHistories(page: number = 1, limit: number = 10) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .get<any>(environment.tcApiUrl + `user/history?page=${page}&limit=${limit}`, { headers })
      .pipe(
        map((activies: any[]) => {
          //Adaptar historial
          const history = {
            bundleOrders: [],
            cryptoTxs: [],
            deposits: [],
            orders: [],
            withdraws: [],
            payroll: [],
          };

          activies.forEach((activity) => {
            let type = activity._type === 'payrollLog' ? 'payroll' : activity._type + 's';
            history[type]?.push(activity);
          });

          return history;
        }),
      )
      .pipe(share());
  }

  downloadHistory() {
    const headers = new HttpHeaders().append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .get(`${environment.tcApiUrl}user/history/csv`, { headers, responseType: 'blob' })
      .pipe(share());
  }

  downloadCryptoTiket(orderId: string) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .get<any>(environment.tcApiUrl + `order/${orderId}/ticket`, { headers })
      .pipe(share());
  }

  getUserLimits() {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http.get<any>(environment.tcApiUrl + `user/limits`, { headers }).pipe(share());
  }

  getFiatStatus() {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .get<IFiatStatus[]>(environment.tcApiUrl + `settings/fiat/status`, { headers })
      .pipe(share());
  }

  // /user/limits

  /////////////////// ---- END TC APIs ---- /////////////////
  //////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////
  //////////////// ---- BANK / ACCOUNT ---- ////////////////

  addAccount(
    currency: string,
    cbu: string,
    description: string,
    bankCode?: string,
    accountType?: string,
  ) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .post<any>(
        environment.tcApiUrl + 'user/bankAccount/' + currency,
        {
          cbu,
          description,
          bankCode,
          accountType,
        },
        { headers },
      )
      .pipe(share());
  }

  removeAccount(cbu: string, currency: string): any {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    const obs = this.http
      .delete<any>(environment.tcApiUrl + `user/bankAccount/${currency}/${cbu}`, { headers })
      .pipe(share());
    return obs;
  }

  addCryptoAccount(chain: number, address: string, description: string) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .post<any>(
        environment.tcApiUrl + 'user/cryptoAccount/' + chain,
        {
          description,
          address,
        },
        { headers },
      )
      .pipe(share());
  }

  removeCryptoAccount(chain: number, address: string) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    const obs = this.http
      .delete<any>(environment.tcApiUrl + `user/cryptoAccount/${chain}/${address}`, { headers })
      .pipe(share());
    return obs;
  }

  withdraw(coin: string, accountIndex: number, amount: number): any {
    // if(!environment.production)
    //   return null;
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    const o = this.http
      .post<any>(
        environment.tcApiUrl + 'withdraw/fiat',
        { coin, accountIndex, amount },
        { headers },
      )
      .pipe(share());
    return o;
  }

  getEmployees() {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .get<any>(environment.tcApiUrl + `user/payroll`, { headers })
      .pipe(map((res) => res.employees || []))
      .pipe(share());
  }

  addEmployee(cuit: string, salary: number, isArs: boolean) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .post<any>(environment.tcApiUrl + `user/payroll/add`, { cuit, salary, isArs }, { headers })
      .pipe(share());
  }

  editEmployee(cuit: string, salary: number, isArs: boolean) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .post<any>(environment.tcApiUrl + `user/payroll/edit`, { cuit, salary, isArs }, { headers })
      .pipe(share());
  }

  removeEmployee(cuit: string) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .post<any>(environment.tcApiUrl + `user/payroll/remove`, { cuit }, { headers })
      .pipe(share());
  }

  sendPaymentsToEmployees() {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .post<any>(environment.tcApiUrl + `user/payroll/execute`, { headers })
      .pipe(share());
  }

  /////////////// ---- BANK / ACCOUNT END ---- //////////////
  //////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////
  //////////////// ---- ONBOARDING / ACCOUNT ---- /////////////

  resendConfirmationMail() {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .post<any>(environment.tcApiUrl + 'user/resendConfirmMail', null, {
        headers,
      })
      .pipe(share());
  }

  uploadPassportImage(image: any, type: number): any {
    const formData: FormData = new FormData();
    formData.append('image', image);

    const headers = new HttpHeaders().append('x-access-token', this.getTokens().jwtToken);

    return this.http
      .post<any>(environment.apiUrl + 'upload/passport_image/' + type.toString(), formData, {
        headers,
      })
      .pipe(share());
  }

  setUploadImageURL(type: number, imageName: string): any {
    const headers = new HttpHeaders().append('x-access-token', this.getTokens().jwtToken);

    const query = '?type=' + type + '&fileName=' + imageName;
    return this.http
      .post<any>(
        environment.apiUrl + 'register/setUploadURLs',
        {
          type,
          fileName: imageName,
        },
        { headers },
      )
      .pipe(share());
  }

  setUploadImageURLSing(): any {
    const headers = new HttpHeaders().append('x-access-token', this.getTokens().jwtToken);

    return this.http
      .post<any>(
        environment.apiUrl + 'register/getUploadURLs',
        {
          name: 'signature.txt',
        },
        { headers },
      )
      .pipe(share());
  }

  uploadPreSignedImageGeneric(url: string, image: any): any {
    const headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data; boundary=something',
      'x-access-token': this.getTokens().jwtToken,
    });

    const formData: FormData = new FormData();

    formData.append('image', image);

    return this.http.put<any>(url, image, { headers }).pipe(share());
  }

  uploadPreSignedImage(url: string, image: any): any {
    const headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data; boundary=something',
      'x-access-token': this.getTokens().jwtToken,
    });

    const formData: FormData = new FormData();

    formData.append('image', image);

    return this.http.put<any>(url, image, { headers }).pipe(share());
  }

  notifyOfImageUploadToS3(url: string, type: number): any {
    const headers = new HttpHeaders().append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .post<any>(
        environment.apiUrl + 'register/imageUploaded',
        {
          url,
          type,
        },
        { headers },
      )
      .pipe(share());
  }

  uploadFundsImage(image: any): any {
    const formData: FormData = new FormData();
    formData.append('image', image);

    const headers = new HttpHeaders().append('x-access-token', this.getTokens().jwtToken);

    return this.http
      .post<any>(environment.apiUrl + 'upload/funds_image', formData, {
        headers,
      })
      .pipe(share());
  }

  //////////////// ---- ONBOARDING / ACCOUNT ---- /////////////
  /////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////
  /////////////////// ---- CRYPTO ---- ////////////////////

  generateAddress(type: string, ticker: string) {
    // type
    // global
    // personal
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    return this.http
      .get<any>(environment.apiUrl + `crypto/deposit/address/${type}/${ticker}`, { headers })
      .pipe(share());
  }

  sendWithdrawEmailVerification(params: {
    address: string;
    amount: string;
    coin: string;
    chain: string;
    costCode: string;
  }) {
    const { address, costCode, amount, coin, chain } = params;
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .post<any>(
        environment.tcApiUrl + 'withdraw/pre',
        {
          address,
          amount,
          coin,
          chain,
          costCode,
        },
        { headers },
      )
      .pipe(share());
  }

  withdrawCrypto(code: string) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .post<ITransaction>(
        environment.tcApiUrl + 'withdraw',
        {
          code,
        },
        { headers },
      )
      .pipe(share());
  }

  getWithdrawCost(ticker: string, wallet: string) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .get<any>(environment.tcApiUrl + `withdraw/estimate?coin=${ticker}&address=${wallet}`, {
        headers,
      })
      .pipe(share());
  }

  downloadTiket(creationTime: string) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .get<any>(environment.apiUrl + `order/${creationTime}/download`, {
        headers,
      })
      .pipe(share());
  }

  // AUX API, pending new one
  getCryptoPricesHistory() {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    return this.http.get<any>(environment.apiUrl + `price/coinHistory`, { headers }).pipe(share());
  }

  getBlockchainStatus() {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .get<IBlockchainStatus[]>(environment.tcApiUrl + `settings/crypto/status`, { headers })
      .pipe(share());
  }

  getOrdersStatus() {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .get<IOrderStatus>(environment.tcApiUrl + `settings/order/status`, { headers })
      .pipe(share());
  }

  ///////////////// ---- CRYPTO END ---- ///////////////////
  /////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////
  ///////////////////// ---- AUTH ---- ////////////////////

  recoverPassword(email: string): Observable<Object> {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    return this.http
      .post(
        environment.tcApiUrl + 'user/recoverPassword',
        { email },
        { ...headers, observe: 'response' },
      )
      .pipe(share());
  }

  changePassword(verificationCode: string, password: string): Observable<Object | void> {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    return this.http
      .post(environment.tcApiUrl + 'user/password', { verificationCode, password }, { headers })
      .pipe(share());
  }

  updatePassword(oldPassword: string, newPassword: string): any {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .put<any>(environment.tcApiUrl + 'user/password', { oldPassword, newPassword }, { headers })
      .pipe(share());
  }

  firstOnboardingFinished() {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    return this.http
      .post<any>(environment.apiUrl + 'register/firstOnboarding', {}, { headers })
      .pipe(share());
  }

  getPersona(passport: string) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('authorization', 'JWT SoyPlunzoPapa');
    return this.http
      .get<any>('https://api.tiendadolar.com.ar/v2/user/getPersona/' + passport, { headers })
      .pipe(share());
  }

  checkExistUser(repCuit: string) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .put<any>(
        environment.tcApiUrl + 'user/rep',
        {
          repCuit,
        },
        { headers },
      )
      .pipe(share());
  }

  ///////////////////// ---- AUTH END---- //////////////////////
  /////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////
  ///////////////////// ---- 2FA ---- //////////////////////

  show2FAQr() {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http.post<any>(environment.tcApiUrl + 'auth/2FA', {}, { headers }).pipe(share());
  }

  verifyActivation2FA(token: string) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .post<any>(environment.tcApiUrl + 'auth/verify2FA', { token }, { headers })
      .pipe(share());
  }

  verifyLogin2FA(
    apiUrl: string,
    token: string,
  ): Observable<{ body: ISuccessLogin; status: number } | HttpResponse<{}> | Error> {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    return this.http
      .post(environment.tcApiUrl + apiUrl, { token }, { headers, observe: 'response' })
      .pipe(share());
  }

  verifyDeactivation2FA(token: string) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);

    return this.http
      .delete<any>(environment.tcApiUrl + `auth/2FA?token=${token}`, { headers })
      .pipe(share());
  }

  getAustData() {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http.get<any>(environment.tcApiUrl + `price/aUST/data`, { headers }).pipe(share());
  }

  isMarketClosed() {
    const headers = new HttpHeaders().append('Content-Type', 'application/json');
    return this.http
      .get<any>(environment.tcApiUrl + 'settings/closed', { headers })
      .pipe(map((res) => res.closed))
      .pipe(share());
  }

  ///////////////////// ---- 2FA END---- //////////////////////
  /////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////
  ///////////////////// ---- REFERRAL ---- ///////////////////

  //   getUserBenefit() {
  //     const headers = new HttpHeaders()
  //       .append('Content-Type', 'application/json')
  //       .append('x-access-token', this.getTokens().jwtToken);
  //     return this.http.get<any>(environment.apiUrl + `user/benefit`, { headers }).pipe(share());
  //   }

  //   getReferralDiscount(price: number, orderType: string, operationType: number) {
  //     const headers = new HttpHeaders().append('Content-Type', 'application/json');
  //     return this.http
  //       .get<any>(
  //         environment.apiUrl +
  //           `user/benefit/discount/${orderType}?op=${operationType}&price=${price}`,
  //         { headers },
  //       )
  //       .pipe(share());
  //   }

  //   setReferee(refLink: string) {
  //     const headers = new HttpHeaders()
  //       .append('Content-Type', 'application/json')
  //       .append('x-access-token', this.getTokens().jwtToken);
  //     return this.http
  //       .post<any>(environment.apiUrl + 'user/benefit/refer', { refLink }, { headers })
  //       .pipe(share());
  //   }

  //   sendReferralInvitationMail(email: string) {
  //     const headers = new HttpHeaders()
  //       .append('Content-Type', 'application/json')
  //       .append('x-access-token', this.getTokens().jwtToken);
  //     return this.http
  //       .post<any>(environment.apiUrl + 'user/benefit/invite', { email }, { headers })
  //       .pipe(share());
  //   }

  //   sendCompanyReferralMail(mail: string) {
  //     const headers = new HttpHeaders()
  //       .append('Content-Type', 'application/json')
  //       .append('x-access-token', this.getTokens().jwtToken);
  //     return this.http
  //       .post<any>(environment.apiUrl + 'user/verify_company_email', { mail }, { headers })
  //       .pipe(share());
  //   }

  //   confirmCompanyReferralByCode(verificationCode: string) {
  //     const headers = new HttpHeaders()
  //       .append('Content-Type', 'application/json')
  //       .append('x-access-token', this.getTokens().jwtToken);
  //     return this.http
  //       .post<any>(
  //         environment.apiUrl + 'user/confirm_company_email',
  //         { verificationCode },
  //         { headers },
  //       )
  //       .pipe(share());
  //   }

  //   modifyReferralLink(refLink: string) {
  //     const headers = new HttpHeaders()
  //       .append('Content-Type', 'application/json')
  //       .append('x-access-token', this.getTokens().jwtToken);
  //     return this.http
  //       .put<any>(environment.apiUrl + 'user/link', { refLink }, { headers })
  //       .pipe(share());
  //   }

  //   getUserReferralLink(refLink: string) {
  //     const headers = new HttpHeaders().append('Content-Type', 'application/json');
  //     return this.http
  //       .get<any>(environment.apiUrl + `user/link/${refLink}`, { headers })
  //       .pipe(share());
  //   }

  /////////////////// ---- REFERRAL ENDS ---- /////////////////
  /////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////
  ///////////////////// ---- STAKING ---- ///////////////////

  //   stakeDeposit(coin: string, amount: string) {
  //     const headers = new HttpHeaders().append('Content-Type', 'application/json');
  //     return this.http
  //       .post<any>(environment.apiUrl + 'stake/deposit', { coin, amount }, { headers })
  //       .pipe(share());
  //   }

  //   stakeWithdraw(coin: string, amount: string, withdrawAll: boolean) {
  //     const headers = new HttpHeaders().append('Content-Type', 'application/json');
  //     return this.http
  //       .post<any>(
  //         environment.apiUrl + 'stake/withdraw',
  //         { coin, amount, max: withdrawAll },
  //         { headers },
  //       )
  //       .pipe(share());
  //   }

  //   getCryptoStakeTNA() {
  //     const headers = new HttpHeaders().append('Content-Type', 'application/json');
  //     // .append('x-access-token', this.getTokens().jwtToken);
  //     return this.http
  //       .get<any>(environment.apiUrl + 'price/crypto/stake/rates', { headers })
  //       .pipe(share());
  //   }
  // }

  /////////////////// ---- STAKING END---- ////////////////////
  /////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////
  ///////////////////// ---- DASHBOARD COMMERCE ---- ///////////////////

  //POST /commerce/register => no recibe nada => retorna {apiKey: string}
  commerceRegister() {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .post<{ apiKey: string }>(environment.tcApiUrl + `commerce/register`, {
        headers,
      })
      .pipe(share());
  }

  getCommerceConfig() {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .get<ICommerceConfig>(environment.tcApiUrl + `commerce/config`, {
        headers,
      })
      .pipe(share());
  }

  setCommerceConfig(newCommerceConfig: ICommerceConfig) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .put<ICommerceConfig>(environment.tcApiUrl + `commerce/detail`, newCommerceConfig, {
        headers,
      })
      .pipe(share());
  }

  getCharges(periodicity: string, page: number) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .get<IChargesResponse>(
        environment.tcApiUrl + `commerce/charges?periodicity=${periodicity}&page=${page}`,
        {
          headers,
        },
      )
      .pipe(
        map((res) => {
          res.periodicity = periodicity;
          return res;
        }),
      )
      .pipe(share());
  }

  createCharge(price: IChargePrice, items: IChargeItem[]) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .post<ICharge>(
        environment.tcApiUrl + `commerce`,
        {
          amount: price.amount,
          currency: price.currency,
          metadata: {
            items,
          },
        },
        {
          headers,
        },
      )
      .pipe(share());
  }

  getCharge(chargeId: String) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .get<ICharge>(environment.tcApiUrl + `commerce/charge/${chargeId}`, {
        headers,
      })
      .pipe(share());
  }

  getChargeStats(periodicity: string) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .get<IChargeStats>(environment.tcApiUrl + `commerce/stats?periodicity=${periodicity}`, {
        headers,
      })
      .pipe(share());
  }

  refundCharge(chargeId: string) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .post(environment.tcApiUrl + `commerce/refund/${chargeId}`, {
        headers,
      })
      .pipe(share());
  }

  resolveCharge(chargeId: string) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .post(environment.tcApiUrl + `commerce/resolve/${chargeId}`, {
        headers,
      })
      .pipe(share());
  }

  changeCommercePassword(newPassword: string) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .put<any>(environment.tcApiUrl + 'pos/password', { newPassword }, { headers })
      .pipe(share());
  }

  /////////////////// ---- DASHBOARD COMMERCE END---- ////////////////////
  /////////////////////////////////////////////////////////////

  /////////////////// ---- COMMERCE ONBOARDING---- ////////////////////
  /////////////////////////////////////////////////////////////

  commerceOnboarding(userData: ICommerceUserForm) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .post(
        environment.tcApiUrl + `contact/commerce/user`,
        {
          ...userData,
        },
        {
          headers,
        },
      )
      .pipe(share());
  }

  /////////////////// ---- COMMERCE ONBOARDING END---- ////////////////////
  /////////////////////////////////////////////////////////////

  /////////////////// ---- PRODE---- ////////////////////
  /////////////////////////////////////////////////////////////

  getProdeTickets(page: number, limit: number) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .get<IProdeTicketsResponse>(environment.tcApiUrl + `prode?page=${page}&limit=${limit}`, {
        headers,
      })
      .pipe(share());
  }

  getProdeTicket(ticketId) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .get<IProdeTicket>(environment.tcApiUrl + `prode/${ticketId}`, {
        headers,
      })
      .pipe(share());
  }

  getProdeConfig() {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .get<IProdeConfig>(environment.tcApiUrl + `prode/config`, {
        headers,
      })
      .pipe(share());
  }

  createProdeTicket(ticket) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .post(
        environment.tcApiUrl + `prode`,
        {
          ticket,
        },
        {
          headers,
        },
      )
      .pipe(share());
  }

  getProdeGroups() {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .get<IProdeGroup[]>(environment.tcApiUrl + `prode/groups`, {
        headers,
      })
      .pipe(share());
  }

  getProdeGroup(groupId, page: number, limit: number) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .get<IProdeGroupRanking>(
        environment.tcApiUrl + `prode/group/${groupId}?page=${page}&limit=${limit}`,
        {
          headers,
        },
      )
      .pipe(share());
  }

  createProdeGroup(name: string) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .post<IProdeGroup>(
        environment.tcApiUrl + `prode/group`,
        {
          name,
        },
        {
          headers,
        },
      )
      .pipe(share());
  }

  joinToProdeGroup(groupId) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .post<IProdeGroup>(environment.tcApiUrl + `prode/group/${groupId}`, {
        headers,
      })
      .pipe(share());
  }

  leaveProdeGroup(groupId) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .delete(environment.tcApiUrl + `prode/group/${groupId}`, {
        headers,
      })
      .pipe(share());
  }

  readProdeCode(code) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .post(
        environment.tcApiUrl + `prode/readme`,
        {
          code,
        },
        {
          headers,
        },
      )
      .pipe(share());
  }

  updateProdeReferralId(referralId) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .post(
        environment.tcApiUrl + `prode/referralId`,
        {
          referralId,
        },
        {
          headers,
        },
      )
      .pipe(share());
  }

  getProdeGlobalRanking(page: number, limit: number) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .get<IProdeGlobalRankingResponse>(
        environment.tcApiUrl + `prode/globalRanking?page=${page}&limit=${limit}`,
        {
          headers,
        },
      )
      .pipe(share());
  }

  /////////////////// ---- PRODE END---- ////////////////////
  /////////////////////////////////////////////////////////////

  sendTikTokEvent(body) {
    const headers = new HttpHeaders()
      .append('Content-type', 'application/json')
      .append('Access-Token', this.getTokens().jwtToken);
    return this.http.post<any>(
      environment.tcApiUrl + 'tiktok/track',
      {
        ...body,
      },
      {
        headers,
      },
    );
  }

  getDataFromCryptoCompare(crypto: string, period: string, limit?: string) {
    const headers = new HttpHeaders()
      .append('content-type', 'application/json')
      .append('Access-Token', this.getTokens().jwtToken);
    return this.http
      .get<CryptoCompareResp>(
        environment.tcApiUrl + `price/info/${crypto}/history?limit=${limit}&period=${period}`,
        { headers },
      )
      .pipe(
        map((res: CryptoCompareResp) => {
          return res.Data.Data.map((newResp: CryptoCompareData) => ({
            time: Utils.timeToLocal(newResp.time),
            high: newResp.high,
            low: newResp.low,
            open: newResp.open,
            close: newResp.close,
          }));
        }),
      );
  }

  getRankFromCoincap(crypto: string) {
    const headers = new HttpHeaders()
      .append('content-type', 'application/json')
      .append('Access-Token', this.getTokens().jwtToken);
    return this.http
      .get<CoincapResp>(environment.tcApiUrl + `price/info/${crypto}`, { headers })
      .pipe(
        map((res: CoincapResp) => {
          return res.data;
        }),
      );
  }

  toggleRules(rule: string) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .post<IRules>(environment.tcApiUrl + `commerce/rule/toggle`, { type: rule }, { headers })
      .pipe(share());
  }

  addRule(body) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http.post<IRuleDto>(environment.tcApiUrl + `dca`, body, { headers }).pipe(share());
  }

  getDcaRules() {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .get<IDcaRulesDto>(environment.tcApiUrl + 'dca', {
        headers,
      })
      .pipe(share());
  }

  getStatsRule(id: string) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .get<IRuleStatsDto>(environment.tcApiUrl + `dca/${id}/stats`, {
        headers,
      })
      .pipe(share());
  }

  deleteRule(id: string) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .delete(environment.tcApiUrl + `dca/${id}`, {
        headers,
      })
      .pipe(share());
  }

  changeStatusRule(id: string, status: string) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .post<IRuleDto>(environment.tcApiUrl + `dca/${id}/status`, { status }, { headers })
      .pipe(share());
  }

  createGroup(name) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .post<IGroup>(environment.tcApiUrl + `games/jueguin/group`, { name }, { headers })
      .pipe(share());
  }

  getGroup() {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .get<IGroup>(environment.tcApiUrl + 'games/jueguin/group', {
        headers,
      })
      .pipe(share());
  }

  joinGroup(groupId) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .post<IGroup>(environment.tcApiUrl + `games/jueguin/group/join`, { groupId }, { headers })
      .pipe(share());
  }

  getLeaderboard() {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .get<ILeaderboard[]>(environment.tcApiUrl + 'games/jueguin/leaderboard', {
        headers,
      })
      .pipe(share());
  }

  createOrderGroup(coin, amount, operation) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .post<IOrderGroup>(
        environment.tcApiUrl + `games/jueguin/order`,
        { coin, amount, operation },
        { headers },
      )
      .pipe(share());
  }

  getPriceGroup(coin) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .get<IPriceGroup>(environment.tcApiUrl + `games/jueguin/price?coin=${coin}`, {
        headers,
      })
      .pipe(share());
  }

  getHistoryGroup(page, limit) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .get<IHistoryGroup[]>(
        environment.tcApiUrl + `games/jueguin/order?page=${page}&limit=${limit}`,
        {
          headers,
        },
      )
      .pipe(share());
  }

  lockPriceWhitelabel(
    coin: 'USDC' | 'WLD',
    amount: number,
    lockType: 'ADDRESS' | 'CUIT',
    against: 'CLP' | 'ARS' = 'ARS',
    chain: number,
    payload: { externalId: string },
  ) {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .post<{ price: number; expires: string; totalFee: number }>(
        environment.tcApiUrl + `order/lock/whitelabel`,
        { lockType, coin: coin + '_' + against, amount: +amount, chain: chain, payload },
        {
          headers,
        },
      )
      .pipe(share());
  }

  createUuid() {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('x-access-token', this.getTokens().jwtToken);
    return this.http
      .post<{ code: string }>(environment.tcApiUrl + `auth/token/`, { headers })
      .pipe(share());
  }
}
