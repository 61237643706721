import { ReminderMessageComponent } from './components/reminder-message/reminder-message.component';
import { FormatCoinAmountPipe } from './pipes/format-coin-amount.pipe';
import { FormatNumberPipe } from './pipes/format-number.pipe';
import { MarkdownModule } from 'ngx-markdown';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WrongDniDialogComponent } from './components/dialogs/wrong-dni-dialog/wrong-dni-dialog.component';
import { CUITPipe } from './pipes/cuit.pipe';
import { IvaPipe } from './pipes/iva.pipe';
import { CBUAliasPipe } from './pipes/cbualias.pipe';
import { AcceptDDJJComponent } from './components/dialogs/accept-ddjj/accept-ddjj.component';
import { CurrencyArsPipe } from './pipes/currency-ars.pipe';
import { TycComponent } from './components/dialogs/tyc/tyc.component';
import { CallbackPipe } from './pipes/callback.pipe';
import { WhatsappChatComponent } from './components/whatsapp-chat/whatsapp-chat.component';
import { HistoryStatusPipe } from './pipes/history-status.pipe';
import { GetConvertedChargeFeePipe } from './pipes/get-converted-charge-fee.pipe';
import { VolumeDayPipe } from './pipes/volume-day.pipe';
import { NotDotDirective } from '../../utils/directives/not-dot.directive';
import { StaticSecondFactorComponent } from './components/static-factor-dialog/static-second-factor.component';
import { CryptoConfirmDialogComponent } from './components/dialogs/crypto-confirm-dialog/crypto-confirm-dialog.component';
import { FrequencyTransformPipe } from './pipes/frequency-transform.pipe';
import { ArsUsdLabelPipe } from './pipes/ars-usd-label.pipe';
import { NgxMaskModule } from 'ngx-mask';
import { GenericSwapComponent } from './components/generic-swap/generic-swap.component';
import { FilterCoinsSwapPipe } from './pipes/filter-coins-swap.pipe';
import { RouterModule } from '@angular/router';
import { AlertDialogComponent } from './components/dialogs/alert-dialog/alert-dialog.component';
import { FiatComponent } from './components/fiat/fiat.component';
import { DocumentSignatureDialogComponent } from './components/dialogs/document-signature-dialog/document-signature-dialog.component';
import { SelectorComponent } from './components/selector/selector.component';

@NgModule({
  declarations: [
    WrongDniDialogComponent,
    CUITPipe,
    CBUAliasPipe,
    IvaPipe,
    CurrencyArsPipe,
    FormatNumberPipe,
    FormatCoinAmountPipe,
    AcceptDDJJComponent,
    TycComponent,
    CallbackPipe,
    WhatsappChatComponent,
    CryptoConfirmDialogComponent,
    HistoryStatusPipe,
    GetConvertedChargeFeePipe,
    VolumeDayPipe,
    NotDotDirective,
    StaticSecondFactorComponent,
    FrequencyTransformPipe,
    ArsUsdLabelPipe,
    GenericSwapComponent,
    FiatComponent,
    FilterCoinsSwapPipe,
    AlertDialogComponent,
    ReminderMessageComponent,
    DocumentSignatureDialogComponent,
    SelectorComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatDialogModule,
    MatFormFieldModule,
    MatCardModule,
    MatChipsModule,
    MatInputModule,
    MatCheckboxModule,
    MatRadioModule,
    MarkdownModule.forChild(),
    NgxMaskModule.forChild(),
    RouterModule,
  ],
  exports: [
    WrongDniDialogComponent,
    CUITPipe,
    CBUAliasPipe,
    IvaPipe,
    CurrencyArsPipe,
    CryptoConfirmDialogComponent,
    AcceptDDJJComponent,
    FormatNumberPipe,
    FormatCoinAmountPipe,
    CallbackPipe,
    WhatsappChatComponent,
    HistoryStatusPipe,
    GetConvertedChargeFeePipe,
    VolumeDayPipe,
    NotDotDirective,
    StaticSecondFactorComponent,
    FrequencyTransformPipe,
    ArsUsdLabelPipe,
    GenericSwapComponent,
    FiatComponent,
    ReminderMessageComponent,
    SelectorComponent,
  ],
  providers: [{ provide: MAT_DIALOG_DATA, useValue: {} }],
})
export class SharedModule {}
