<div class="container">
  <div class="close" (click)="dialogRef.close()">
    <img src="assets/icons/news/close.svg" alt="close modal" />
  </div>

  <h1 [class.error]="data.isError">{{ data.title }}</h1>

  <p class="content" *ngIf="data.content" [innerHtml]="data.content"></p>

  <mat-checkbox
    *ngIf="data.agreementText"
    class="textAccept"
    [labelPosition]="'after'"
    name="acceptTerms"
    [(ngModel)]="agreementValue"
  >
    {{ data.agreementText }}
  </mat-checkbox>

  <div class="buttons">
    <button
      class="td-btn primary"
      (click)="dialogRef.close(false)"
      *ngIf="data.type !== CONFIRM_DIALOG_TYPE.COUNTDOWN"
    >
      {{ data.rejectLabel ?? 'Cancelar' }}
    </button>
    <button
      class="td-btn primary"
      [class.error]="data.isError"
      (click)="dialogRef.close(true)"
      [disabled]="this.timeToActivate > 0 || (data.agreementText && !agreementValue)"
    >
      {{ data.acceptLabel ?? 'Confirmar' }}
      {{ data.type === CONFIRM_DIALOG_TYPE.COUNTDOWN ? this.getCountdown() : '' }}
    </button>
  </div>

  <div class="error-code" *ngIf="data.errorCode">
    <p>{{ data.errorCode }}</p>
  </div>
</div>
